import React, { useMemo } from "react";
import {Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import axios from 'axios';
import {Field} from "formik";
import {APPROVED, OPENED, MOH, ADMIN, STATUSES, baseURLmicroServiceFile, microServiceFileSeparator} from "../../Helpers/constants";
import RowSelectAutocompleteInput from "../../FormElements/RowSelectAutocompleteInput";
import RowInput from "../../FormElements/RowInput";
import GenericListContainer from "../../GenericList/GenericListContainer";
import AutocompleteFromAPI from "../../FormElements/AutocompleteFromAPI";
import {GETREASONCODE} from "../../GraphQl/query";
import {useLazyQuery} from "@apollo/client";
import {FileUpload} from "../../FormElements";
import styles from "./ReasonCodeModalStyle.module.css"
import {duplicateResponse} from "../../Helpers/functions";

// eliminato MANAGER rispetto a quello globale
const ROLES_TO_SEE_STATUS = [MOH, ADMIN];


const ReasonCodeModalFormComponent = ({readOnly, toggleModal, setFieldValue, translate, handleSubmit, values, nestedModal, closeAll, toggleAll, toggleNested, isCloned, orgVersionStatus, userRole}) => {
    
   
  
    const T_STATUSES = useMemo(() => STATUSES.map(status => ({value: status, label: translate(status)})), [translate]);
    const [getCodesV2, { loading, data }] = useLazyQuery(GETREASONCODE);
    const structureName="ReasonCode";

    const makeLink = async (event, val) => {
        // old link
        if (!val.includes(microServiceFileSeparator)) return;
        event.preventDefault();
        const { data } = await axios.post(baseURLmicroServiceFile + '/read', { fileName: val });
        window.open(data.url, "_blank")
    }

    const isSubmitDisabled = ['Approvato', 'Verificato'].includes(orgVersionStatus);

    if (loading) return <p>Loading ...</p>;

    return (
        <form onSubmit={handleSubmit}>
            <ModalHeader toggle={() => toggleModal(false)}>{translate("yourComment")}</ModalHeader>
            <ModalBody>
                <Col> <Field disabled={!ROLES_TO_SEE_STATUS.includes(userRole)} label={translate('selectStatus')} list={T_STATUSES} name={'status'}
                             component={RowSelectAutocompleteInput}  structureName={structureName}
                />
                </Col>
                <Col>
                    <Row>
                        <Col hidden={!ROLES_TO_SEE_STATUS.includes(userRole)} xs="10" sm="9">
                            <Field label={translate('selectReasonCode')}
                                   structureName={structureName}
                                   name={'reasonCodeId'} component={AutocompleteFromAPI}
                                   labelcols={4}/>
                        </Col>
                        <Col sm="3"><Button hidden={!ROLES_TO_SEE_STATUS.includes(userRole)} color="success" onClick={ ()=>{getCodesV2();toggleNested();}}>+</Button></Col>
                        <Modal size='lg' isOpen={nestedModal} toggle={toggleNested} onClosed={closeAll ? toggleModal : undefined}>
                            <ModalHeader>{translate("nestedModal")}</ModalHeader>
                            <ModalBody>{(data && data.result)&&<GenericListContainer list={duplicateResponse(data.result)} structureName={"ReasonCode"} inmodal />}</ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={toggleNested}>{translate('done')}</Button>
                                {' '}
                                <Button color="danger" onClick={toggleAll}>{translate('closeAll')}</Button>
                            </ModalFooter>
                        </Modal>
                    </Row>
                </Col>
                <Col>
                    <Field
                        label={translate("leaveComment")}
                        name={'comment'}
                        type='textarea'
                        disabled={!ROLES_TO_SEE_STATUS.includes(userRole)}
                        component={RowInput}
                    />
                </Col>
                <Col>
                    <Field
                        label={translate("reply")}
                        name={'userComment'}
                        type='textarea'
                        disabled={ROLES_TO_SEE_STATUS.includes(userRole) || values.status === APPROVED || orgVersionStatus !== translate(OPENED)}
                        component={RowInput}
                    />
                </Col>
                <Col hidden={!ROLES_TO_SEE_STATUS.includes(userRole) && (orgVersionStatus !== translate(OPENED) || values.status === APPROVED)}>
                    <Field label="File da caricare" component={FileUpload}
                           onload={value => setFieldValue('file', value)
                           }/>
                </Col>
                {(values.attachmentName && values.attachmentPath) &&<Col>
                    <FormGroup row>
                    <Label xs={3} className={`label-color text-left`}>{translate("attachment")}</Label>
                    <Col xs={9} className={styles.link}><a href={values.attachmentPath} onClick={e => makeLink(e, values.attachmentPath)} target="_blank" rel="noopener noreferrer">{values.attachmentName}</a></Col>
                    </FormGroup>
                </Col>}
            </ModalBody>
            <ModalFooter>
              
                {!readOnly &&
                  
                  <Button active={!readOnly} type="submit" color="primary" disabled={isSubmitDisabled} onClick={() => toggleModal(false)}>{translate('submitStatus')}</Button>
                  
                }
              
                
                <Button onClick={() => toggleModal(false)} color="danger">{translate("close")}</Button>
            </ModalFooter>
        </form>);
};

export default ReasonCodeModalFormComponent;
