import React from 'react';
import {RULES} from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";
import {withRouter} from "react-router-dom";
import {Query} from "@apollo/client/react/components";
import {duplicateResponse} from "../Helpers/functions";
import {Spinner} from "reactstrap";


const VerificationRuleContainer = (props) => {

        const structureId = props.match.params.structureId;
        return (
            <Query
                query={RULES} variables={{structureId: parseInt(structureId)}}>
                {({loading, error, data}) => {
                    if (loading) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
                    if (error) return <div>{error.message}</div>;
                    const errorList = duplicateResponse(data.result)
                    return (
                        <GenericListContainer list={errorList}
                                              structureName={"ValidationRule"}
                                              parentValue={"validationRuleList"}

                        />
                    )
                }}
            </Query>
        )
};

export default withRouter(VerificationRuleContainer);
