import React from 'react';
import GenericListContainer from "../GenericList/GenericListContainer";
import {FIND_PROJECT, GET_BUDGET_TEMPLATE, GET_BUDGETS_QUERY, GET_PROJECT_UNITS} from "../GraphQl/query";
import {useQuery} from "@apollo/client";
import {withRouter} from "react-router-dom";
import {duplicateResponse, keyValue} from "../Helpers/functions";
import {Spinner} from "reactstrap";

const BudgetContainer = (props) => {
    const budgetId = props.match.params.budgetId;
    // const projectId = props.match.params.projectId;
  
    let missingheader = "Foo";
    
    // const {loading: u_loadingProject, data: u_dataProject, error: u_errorProject} = useQuery(FIND_PROJECT, {
    //   variables: { projectId: parseInt(projectId) },
    // });

    const {data, loading, error} = useQuery(GET_BUDGET_TEMPLATE, {
        variables: {projectId: parseInt(budgetId)},
        fetchPolicy: "network-only"
    });
    if (loading) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
    if (error) return <div>{error.message}</div>;
    
    //const projectList = duplicateResponse(data.result).map(row => ({ ...row, fiscalCode: row?.organization?.fiscalCode }));
  
   
    
    const budgetList = duplicateResponse(data.result);
  

  

    
    // if (u_dataProject !== undefined &&
    //   u_dataProject.hasOwnProperty('result') &&
    //   u_dataProject.result !== undefined &&
    //   u_dataProject.result.hasOwnProperty('code') &&
    //   u_dataProject.result.hasOwnProperty('instituteDescription')) {
    //   
    //   missingheader = u_dataProject.result.code + "\n" + u_dataProject.result.instituteDescription;
    //   
    // }
  
    //keyValue(projectList);
    
    return (
        <GenericListContainer 
            list={budgetList}
            structureName={"Budgets"}
            missingheader={missingheader}
        />
    )
}


export default withRouter(BudgetContainer);

