export const DATE_FORMAT = 'DD-MM-YYYY';
export const TO_HIDE = ["ID", "projectId", "typeCode", "instituteCode", 'Institute Code', 'Row', "structureId", "organizationVersionId", "subcategoryId", "modelId", "parent"];
export const TO_HIDE_FROM_PROJECT = [/*"startingDate", "endingDate",*/ "extensionDate"]; // PM20210705

export const ADMIN = "ADMIN";
export const MANAGER = "PROJECT";
export const MOH = "MOH";
export const OPENED = 'OPENED';
export const APPROVED = 'APPROVED';
export const COMPLETED = 'COMPLETED';
export const CONSOLIDATED = 'CONSOLIDATED';
export const SNAPSHOTTED = 'SNAPSHOTTED';
export const SUBMITTED = 'SUBMITTED';
export const VERIFIED = 'VERIFIED';
export const REJECTED = 'REJECTED';
export const INVESTIGATING = 'INVESTIGATING';
export const MODIFIED = 'MODIFIED';
export const ORG_VERSION_STATUSES = [SUBMITTED, OPENED, COMPLETED, SNAPSHOTTED, CONSOLIDATED];
export const CATEGORIE_X_SINTESI_RF = [10, 13, 17, 19];
export const STATUSES = [APPROVED, REJECTED, INVESTIGATING, MODIFIED];
export const ROLES_TO_SEE_STATUS = [MOH, ADMIN, MANAGER];

export const ROLES_TO_SEE_STATUS_RESTRICTED = [MOH, ADMIN];

export const commentModalInitValues = {
    status: "",
    reasonCodeId: "",
    comment: "",
    userComment: "",
    attachmentName: "",
    attachmentPath: ""
};

export const baseURLmicroServiceFile = process.env.REACT_APP_FILE_URL;
export const oldbaseURLmicroServiceFile = 'https://microservice-file.api.cbim.it/';
export const microServiceFileSeparator = '_##_';

export const API_TYPES = ["unit", "reasonCode", "API"]

export const types = [
    "String",
    "Integer",
    "Boolean",
    "Date",
    "BigDecimal",
    "Dropdown",
    "DropdownApi",
];
export const calcTypes = [
    "percentage",
    "sum",
    "multiplyByRatio",
    "multiply",
    "subtraction"
];

export const roles = ["ADMIN", "PROJECT", "APPROVE", "MOH", "USER"];

export const FLAG_COLS = [
    'publicMoratorium', 
    'flgFreeTitle', 
    'previous_version', 
    'invoice_multiline', 
    'flgSupport' 
];

// export const STRUCTURES_TO_CONSOLIDATE = [3, 5, 6, 10, 11, 12, 13];

export const totalsStructure = [
    {
        Header: "numrow",
        accessor: "numrow",
        type: "Int",
    },
    {
        Header: "structureId",
        accessor: "structureId",
        type: "Int",
    },
    {
        Header: "organizationVersionId",
        accessor: "organizationVersionId",
        type: "Int",
    },
    {
        Header: "subcategoryId",
        accessor: "subcategoryId",
        type: "Int",
    },
    {
        Header: "modelId",
        accessor: "modelId",
        type: "Int",
    },
    {
        Header: "status",
        accessor: "status",
        type: "String",
    },
    {
        Header: "Descrizione",
        accessor: "description",
        type: "String",
    },
    {
        Header: "Costo Totale",
        accessor: "total_cost",
        type: "BigDecimal",
    },
    {
        Header: "Costo Totale (%)",
        accessor: "total_cost_perc",
        type: "BigDecimal",
    },
    {
        Header: "Costo Eleggibile",
        accessor: "eligible_cost",
        type: "BigDecimal",
    },
    {
        Header: "Costo Eleggibile (%)",
        accessor: "eligible_cost_perc",
        type: "BigDecimal",
    },
    {
        Header: "Costo Totale Corrente",
        accessor: "current_total_cost",
        type: "BigDecimal",
    },
    {
        Header: "Costo Eleggibile Corrente",
        accessor: "current_eligible_cost",
        type: "BigDecimal",
    },
    {
        Header: "",
        id: "details",
        filterable: false,
        sortable: false,
        minWidth: 90,
        type: "details"
    }

];

export const StructureMOHStructure = [
    {
        Header: "structureId",
        accessor: "id",
        type: "Int",
    },
    {
        Header: "Tipo",
        accessor: "description",
        type: "String",
    },
    {
        Header: "Numero Totale",
        accessor: "totalProjects",
        type: "Int",
    },
    {
        Header: "Approvati",
        accessor: "approvedProjects",
        type: "Int",
    },
    {
        Header: "Da Verificare",
        accessor: "submittedProjects",
        type: "Int",
    },
    {
        Header: "",
        id: "details",
        filterable: false,
        sortable: false,
        minWidth: 90,
        type: "details"
    }
];
