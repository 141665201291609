import React from 'react';
import {Query} from "@apollo/client/react/components";
import i18n from "i18next"
import {GET_CATEGORIES} from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";
import {withRouter} from "react-router-dom";
import {SUBCATEGORIESLIST} from "../../navigation/Routes";
import {duplicateResponse} from "../Helpers/functions";
import {Spinner} from "reactstrap";

const CategoriesList = (props) => {

    const toSubCategoryList = (id) => {
        props.history.push(`${SUBCATEGORIESLIST}/${id}`)
    };

    return (
        <Query query={GET_CATEGORIES}>
            {({loading, error, data}) => {
                if (loading) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
                if (error) return error.message;
                const list = duplicateResponse(data.result)
                return (
                    <GenericListContainer list={list} structureName={"Category"}
                                          detailsBtnLabel={i18n.t("toSubcategories")}
                                          linkFunction={toSubCategoryList} btnLabel={"Create Category"}
                    />
                )
            }}
        </Query>
    )
};

export default withRouter(CategoriesList);
