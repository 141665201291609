export const HOME = '/';
export const LOGIN = '/login';
export const UNAUTHORIZED = '/403';
export const CATEGORIESLIST = '/categories';
export const STRUCTURE = '/structure';
export const STRUCTUREYEARS = '/structureyears';
export const STRUCTURESUBCATEGORIESLIST = '/structsubcategory';
export const SUBCATEGORIESLIST = '/subcategories';
export const SUBCATEGORIESTOTALS = '/totals';
export const MODELS = '/models';
export const FINANCIAL = '/financial';
export const ORG = '/organization';
export const PRJ = '/project';
export const PROJECTSBYSTRUCTUREYEAR  = '/projectsbystructureyear';
export const PRJS = '/structures';
export const MANAGERPAGE = '/manager';
export const PRJVERSION = '/prjversion';
export const ORGVERSION = '/orgversion';
export const UPLOAD = '/upload';
export const ITEM = '/items';
export const ERRORS = '/errors';
export const RULES = '/rules';
export const LOGINSSO = '/loginsso';
export const MOHPAGE = '/mohpage';
export const PRJUNITS = '/projectunits';
export const BUDGETS = '/budgets';
