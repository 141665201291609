export const statusColumn = {
    Header: "status",
    accessor: "status",
    type: {
        "name": "String",
        "kind": "SCALAR",
        "ofType": null
    }
};
export const reasonCodeColumn = {
    Header: "reasonCode",
    accessor: "reasonCode",
    type: {
        "name": "String",
        "kind": "SCALAR",
        "ofType": null
    }
};
export const isClonedColumn = {
    Header: "isCloned",
    accessor: "parent",
    type: {
        "name": "Boolean",
        "kind": "SCALAR",
        "ofType": null
    }
};
export const viewCol = {
    Header: "",
    id: "view",
    filterable: false,
    sortable: false,
    minWidth: 80,
    type: {name: "View"}
};
export const updateCol = {
    Header: "",
    id: "update",
    filterable: false,
    sortable: false,
    minWidth: 80,
    type: {name: "Update"}
};
export const deleteCol = {
    Header: "",
    id: "delete",
    filterable: false,
    sortable: false,
    minWidth: 80,
    type: {name: "Delete"}
};
export const errorCol = {
    Header: "",
    id: "error",
    filterable: false,
    sortable: false,
    type: {name: "Error"}
};
export const submitCol = {
    Header: "",
    id: "submit",
    filterable: false,
    sortable: false,
    type: {name: "Submit"}
};
export const select = {
    Header: "Sel.",
    id: "select",
    sortable: false,
    filterable: false,
    type: {name: "select"}
};

export const cloneCol = {
    Header: "Clone",
    id: "clone",
    sortable: false,
    filterable: false,
    type: {name: "clone"}
};
export const manageUserCol = {
    Header: "",
    id: "manageUser",
    sortable: false,
    filterable: false,
    type: {name: "manageUser"}
};
export const organizationFiscalCodeCol = {
    Header: "Codice Fiscale",
    accessor: "fiscalCode",
    type: {
        "name": "String",
        "kind": "SCALAR",
        "ofType": null
    }
};