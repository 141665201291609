import React, {useReducer, useState} from "react";
import {Formik} from "formik";
import {Container, Modal, ModalBody, ModalHeader} from "reactstrap";
import {calcTypes} from "../Helpers/constants";
import GenericFormContainer from "../GenerikForm/GenerikFormContainer";
import ModelItemsFormComponent from "./ModelItemsFormComponent";
import {useTranslation} from "react-i18next";
import {useMutation} from "@apollo/client";
import {useLocation, useParams} from "react-router-dom";
import {CREATE_MODEL_MUTATION, UPDATE_MODEL} from "../GraphQl/mutation";
import AlertComponent from "./AlertComponent";

const initItems =
    [{
        "Header": "Record",
        "accessor": "ID",
        "type": "String",
        "calc": "",
        "calculation_type": "",
        "api": "",
        "subcategory": ""
    }];

const modeParams = {"active": "", "description": "", "uploadEnabled": ""};

const setCalculationOptions = (values) => {
    return values.initItems.filter(item => item.type === "BigDecimal")?.map(item => item.accessor)
};

const initialState = {options: [], calculation: []};

const reducer = (state, action) => {
    const type = (calcTypes.includes(action.name) || calcTypes.includes(action.type)) ? "Calculation" : action.type;
    switch (type) {
        case 'Dropdown': {
            const calculation = state.calculation.length ? state.calculation : [];
            if (action.action) {
                state.options.find(items => items.accessor === action.accessor).options.splice(action.index, 1);
                return {options: state.options, calculation: calculation};
            }
            const findOptions = state.options.filter(items => items.accessor === action.accessor);
            const findOptionIndex = state.options.findIndex(items => items.accessor === action.accessor);
            if (findOptions.length) {
                state.options[findOptionIndex] = {...findOptions[findOptions.length - 1], ...action};
                return {options: state.options, calculation: calculation};
            } else {
                return {options: [...state.options, action], calculation: calculation};
            }
        }
        case "Calculation": {
            const options = state.options.length ? state.options : [];
            const findCalculation = state.calculation.filter(items => items.accessor === action.accessor && !items.parameters.includes(action.parameters));
            if (action.action) {
                state.calculation.find(items => items.accessor === action.accessor).parameters.splice(action.index, 1);
                return {calculation: state.calculation, options: options};
            }
            const findCalculationIndex = state.calculation.findIndex(items => items.accessor === action.accessor);
            if (findCalculation.length) {
                state.calculation[findCalculationIndex].parameters = findCalculation[findCalculation.length - 1].parameters.concat(action.parameters);
                return {calculation: state.calculation, options: options};
            } else {
                action.parameters = [action.parameters];
                const calculation = [...state.calculation, action];
                return {calculation: calculation, options: options}
            }
        }
        default:
            return {options: state.options, calculation: state.calculation};
    }
};

const prepareModelItems = (items, state) => {
    switch (items.type) {
        case "Dropdown": {
            if (items.hasOwnProperty("api") && items.api) {
                const {calc, calculation_type, ...result} = items;
                return {...result, type: "Dropdown"};
            } else {
                const option = state.options.find(item => item.accessor === items.accessor);
                const {type, accessor, ...optionValues} = option;
                items.options = optionValues.options;
                const {api, calc, calculation_type, ...result} = items;
                return result;
            }
        }
        case "BigDecimal": {
            if (calcTypes.includes(items.calculation_type)) {
                const parameters = state.calculation.find(item => item.accessor === items.accessor);
                const {accessor, ...calculationParams} = parameters;
                items.calculation = calculationParams;
                const {api, calc, calculation_type, ...result} = items;
                return result;
            } else {
                const {api, calc, calculation_type, ...result} = items;
                return result
            }
        }
        case "DropdownApi": {
            const {calc, calculation_type, ...result} = items;
            return {...result, type: "Dropdown"};
        }
        default: {
            const {api, calc, calculation_type, ...result} = items;
            return result;
        }
    }
};

const setOnSuccessAlert = (setColor, setMessage, toggleAlert) => {
    setColor("success");
    setMessage("Model Saved");
    toggleAlert(true);
    setTimeout(() => toggleAlert(false), 2500)
};

function getInitialValues(location, initItems) {
    if (location.state) {
        const {subcategory, items, ...modelDesc} = location.state.values;
        return {...modelDesc, subCategory: subcategory.description, subCategoryId: subcategory.id, initItems};
    } else
        return {...modeParams, initItems}
}

function setInitItems(location) {
    return location.state ? JSON.parse(location.state.values.items).map(items => {
        if (items.hasOwnProperty("calculation")) {
            const calculation_type = items.calculation.name;
            return {...items, calc: true, calculation_type: calculation_type}
        } else if (items.hasOwnProperty("options") && items.type === "Dropdown") {
            const options = items.options;
            return {...items, options: options}
        } else {
            return items
        }
    }) : initItems
}

const prepareInitCalculation = (initItems) => {
    return initItems.map(({accessor, calculation}) => ({
        accessor: accessor,
        parameters: calculation?.parameters,
        name: calculation?.name
    })).filter(item => item.parameters)
};
const prepareInitDropdown = (initItems) => {
    return initItems.map(({accessor, options, type, api}) => ({
        accessor,
        options,
        type,
        api
    })).filter(item => item.type === "Dropdown")
};

function setId(values, subcategoryId, isCloneAction) {
    if (isCloneAction)
        return parseInt(values.subcategory ? values.subcategory : values.subCategoryId)
    else
        return values.id ? values.id : parseInt(values.subcategory) ? parseInt(values.subcategory) : parseInt(subcategoryId);
}

function getMutation(location) {
    if (location.state?.isCloneAction)
        return CREATE_MODEL_MUTATION
    else
        return location.state ? UPDATE_MODEL : CREATE_MODEL_MUTATION;
}

function getUploadEnabled(values) {
    if (typeof values.uploadEnabled === "boolean")
        return values.uploadEnabled
    return values.uploadEnabled ? values.uploadEnabled.length > 0 : false;
}

const ModelItemsFormContainer = () => {
    const {subcategoryId} = useParams();
    const location = useLocation();
    const initItems = setInitItems(location);
    const isDisabled = location.state?.isDisabled
    const isCloneAction = location.state?.isCloneAction
    const init = (initialState) => {
        const calculationParams = prepareInitCalculation(initItems);
        const dropdownOptions = prepareInitDropdown(initItems);
        initialState = {
            options: dropdownOptions ? dropdownOptions : [],
            calculation: calculationParams ? calculationParams : []
        };
        return initialState
    };
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const mutation = getMutation(location, isCloneAction);
    const [isModalOpen, toggleModal] = useState(false);
    const [isAlertOpen, toggleAlert] = useState(false);
    const [color, setColor] = useState("");
    const [message, setMessage] = useState("");
    const [structure, setStructure] = useState({});
    const [saveModel] = useMutation(mutation,
        {
            onCompleted(data) {
                setOnSuccessAlert(setColor, setMessage, toggleAlert);
            }
        });
    const {t} = useTranslation();
    return (
        <Container>
            <AlertComponent color={color} isAlertOpen={isAlertOpen} message={message} toggleAlert={toggleAlert}/>
            <Modal isOpen={isModalOpen} toggle={() => {
                toggleModal(false)
            }} size="lg">
                <ModalHeader toggle={() => {
                    toggleModal(false);
                }}>{"Preview"}</ModalHeader>
                <ModalBody>
                    <GenericFormContainer label="Salva"
                                          structure={structure.initItems?.map(items => prepareModelItems(items, state))}
                                          structureName={"Financial"}
                                          toggleModal={toggleModal}/>
                </ModalBody>
            </Modal>
            <Formik
                initialValues={getInitialValues(location, initItems)}
                onSubmit={async (values) => {
                    const newValues = values.initItems.map(items => prepareModelItems(items, state));
                    const active = values.active ? values.active.length > 0 : false;
                    const uploadEnabled = getUploadEnabled(values);
                    const id = setId(values, subcategoryId, isCloneAction);
                    const sa = {
                        "uploadEnabled": uploadEnabled,
                        "active": typeof values.active === "object" ? active : values.active,
                        "description": values.description,
                        "items": JSON.stringify(newValues)
                    };
                    try {
                        await saveModel({variables: {id: id, data: sa}});
                    } catch (error) {
                        setColor("danger");
                        setMessage(error);
                        toggleAlert(true);
                        setTimeout(() => toggleAlert(false), 2500)
                    }
                }
                }>
                {({values}) => (
                    initItems? ModelItemsFormComponent(values, dispatch, setCalculationOptions, initItems, toggleModal, setStructure, state, t, isDisabled, isCloneAction, parseInt(subcategoryId)): null
                )}
            </Formik>
        </Container>
    )
};

export default ModelItemsFormContainer

