import {Alert} from "reactstrap";
import React, {useEffect} from "react";

const AlertComponent = ({color, isAlertOpen, toggleAlert, message}) => {
    useEffect(() => {
        const timer = setTimeout(() => toggleAlert(false), 2500);
        return () => clearTimeout(timer);
    }, [toggleAlert]);

    return (<Alert color={color} isOpen={isAlertOpen} toggle={() => toggleAlert(false)}>
        {`${message}`}
    </Alert>)
};
export default AlertComponent