import React from "react";
import {Button, Col, Form, Row} from "reactstrap";
import {Field} from "formik";
import RowInput from "../FormElements/RowInput";
import styles from "../Styles/List.module.css";

const AuthFormComponent = ({
                               isSubmitting,
                               handleSubmit,
                               label = "Login",
                           }) => {

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Row className={styles.tablePadding}>
                    <Col md={{size: 4, offset: 3}}>
                        <Field className="form-control-sm" formGroupClass=" mb-1" name="username"
                               label="Username"
                               labelcols={6}
                               component={RowInput}/>

                    </Col>
                    <Col md={{size: 4, offset: 3}}>
                        <Field className="form-control-sm" formGroupClass=" mb-1" label="Password"
                               name="password" component={RowInput} type='password'
                               labelcols={6}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={{size: 4, offset: 5}} className="text-md-left pt-1 btn-confirm">
                        <Button color="success" type="submit" disabled={isSubmitting}>{label}</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
};

export default AuthFormComponent;
