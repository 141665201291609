import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Unauthorized from '../components/Unauthorized';
import * as routes from './Routes';
import CategoriesList from '../components/Categories'
import SubCategoriesList from '../components/SubCategory/SubCategory'
import ModelListContainer from "../components/Model/ModelListContainer";
import HomePage from "../components/Home";
import AuthFormContainer from "../components/AuthForm/AuthFormContainer";
import OrganizationList from "../components/Organization/OrganizationList";
import ProjectList from "../components/Project/ProjectList";
import TipologieProgetto from "../components/Structure/TipologieProgetto";
import FinancialContainer from "../components/Financials/FinancialContainer";
import SubcategoryTotals from "../components/Totals/SubcategoryTotals";
import UploadFormContainer from "../components/UploadForm/UploadFormContainer";
import ModelItemsFormContainer from "../components/ModelItemsForm/ModelItemsFormContainer";
import StructureList from "../components/Structure/StructureList";
import StructureSubcategory from "../components/Structure/StructureSubcategory";
import VerificationRuleContainer from "../components/ErrorListContainer/VerificationRuleContainer";
import ErrorListContainer from "../components/ErrorListContainer/ErrorListContainer";
import SSOAUTH from "../components/AuthSSO";
import ProjectMangerContainer from "../components/ProjectManagerPage/ProjectManagerPage";
import BudgetContainer from "../components/Budget/budget";
import ProjectVersionContainer from "../components/ProjectVersion/ProjectVersionContainer";
import OrgVersionContainer from "../components/OrganizationVersionContainer/OrganizationVersionContainer";
import MOHPageContainer from '../components/MOHPage/MOHPage';
import ProjectUnitContainer from '../components/ProjectUnit/ProjectUnit';
import {PROJECTSBYSTRUCTUREYEAR, STRUCTUREYEARS} from "./Routes";
import StructureListYearsContainer from "../components/Structure/StructureListYearsContainer";
import ProjectsByStructureYear from "../components/Project/ProjectsByStructureYear";


export const AppRoutes = () =>
    <Switch>
        <Route path={`${routes.SUBCATEGORIESLIST}/:id?`} exact component={() => <SubCategoriesList/>}/>
        <Route path={routes.HOME} exact component={() => <HomePage/>}/>
        <Route path={routes.LOGIN} exact component={() => <AuthFormContainer/>}/>
        <Route path={`${routes.LOGINSSO}/:token`} exact component={() => <SSOAUTH/>}/>
        <Route path={`${routes.LOGINSSO}/:token/:code`} component={() => <SSOAUTH/>}/>
        <Route path={routes.ORG} exact component={() => <OrganizationList/>}/>
        <Route path={routes.PRJ} exact component={() => <ProjectList/>}/>
      
        <Route path={`${routes.PRJS}/:instituteCode`} exact component={() => <TipologieProgetto/>}/>
        <Route path={`${routes.PROJECTSBYSTRUCTUREYEAR}/:structureId/:year`} exact component={() => <ProjectList/>}/>
        <Route path={`${routes.PROJECTSBYSTRUCTUREYEAR}/:code`} exact component={() => <ProjectList/>}/>
      
        <Route path={`${routes.ORGVERSION}/:projectVersionId`} exact component={() => <OrgVersionContainer/>}/>
        <Route path={routes.MANAGERPAGE} exact component={() => <ProjectMangerContainer/>}/>
        <Route path={`${routes.PRJVERSION}/:projectId`} exact component={() => <ProjectVersionContainer/>}/>
        <Route path={`${routes.UPLOAD}/:id/:orgVersionId`} exact component={() => <UploadFormContainer/>}/>
        <Route path={routes.CATEGORIESLIST} exact component={() => <CategoriesList/>}/>
        <Route path={routes.STRUCTURE} exact component={() => <StructureList/>}/>
      
        <Route path={`${routes.STRUCTUREYEARS}/:structureId`} exact component={() => <StructureListYearsContainer/>}/>
      
        <Route path={`${routes.STRUCTURESUBCATEGORIESLIST}/:structureId?`} exact component={() => <StructureSubcategory/>}/>
        <Route path={`${routes.MODELS}/:id`} exact component={() => <ModelListContainer/>}/>
        <Route path={`${routes.MODELS}`} exact component={() => <ModelListContainer/>}/>
        <Route path={`${routes.FINANCIAL}/:id/:orgVersionId`} exact component={() => <FinancialContainer/>}/>
        <Route path={`${routes.SUBCATEGORIESTOTALS}/:orgVersionId/:structureId`} exact
               component={() => <SubcategoryTotals/>}/>
        <Route path ={`${routes.ERRORS}/:orgVersionId`} exact component={() => <ErrorListContainer/>}/>
        <Route exact path={routes.UNAUTHORIZED} component={() => <Unauthorized/>}/>
        <Route exact path={`${routes.ITEM}/:subcategoryId`} component={() => <ModelItemsFormContainer/>}/>
        <Route path ={`${routes.ERRORS}/:orgVersionId`} exact component={() => <ErrorListContainer/>}/>
        <Route path ={`${routes.RULES}/:structureId`} exact component={() => <VerificationRuleContainer/>}/>
        <Route path={routes.MOHPAGE} exact component={() => <MOHPageContainer/>}/>
        <Route path={`${routes.PRJUNITS}/:projectId`} exact component={() => <ProjectUnitContainer/>}/>
        <Route path={`${routes.BUDGETS}/:budgetId`} exact component={() => <BudgetContainer/>}/>
    </Switch>;

export default AppRoutes;
