import React from 'react';
import GenericListContainer from "../GenericList/GenericListContainer";
import {GET_LOGIN_DATA, GET_PROJECT} from "../GraphQl/query";
import {useQuery} from "@apollo/client";
import {duplicateResponse, keyValue} from "../Helpers/functions";
import {PRJVERSION, PRJUNITS, BUDGETS} from "../../navigation/Routes";
import {Spinner} from "reactstrap";


const toProjectVersion = (projectId, history, project) => {
  
    //object
    //id 135
    //code CCR-2017-23669076
  
    //if (project === undefined) {
    //  console.log("project is undefined");
    //} else {
    //  keyValue(project);
    //}
    
    history.push({
      
        pathname: `${PRJVERSION}/${projectId}`,
        state: {
          
            projectCode: project.code,
            missingheader: project.code + "\n" + project.instituteDescription,
            
        }
    })
};

const toProjectUnits = (id, history) => {
    history.push(`${PRJUNITS}/${id}`)
};

const toBudgetPopUp = (id, history) => {
  
  alert("id: " + id);
  history.push(`${BUDGETS}/${id}`);
  
};

const ProjectMangerContainer = () => {
    const {data: auth} = useQuery(GET_LOGIN_DATA);
    const userId = auth ? auth.userInfo.id : "";
    const {data, loading, error} = useQuery(GET_PROJECT, {
        variables: {managerId: userId}
    });
    if (loading) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
    if (error) return <div>{error.message}</div>;
    const projectList = duplicateResponse(data.result)
    return (
        <GenericListContainer list={projectList}
                              structureName={"Project"}
                              parentValue={"managerPage"}
                              linkFunction={toProjectVersion}
                              secondLink={toProjectUnits}
                              budgetLink={toBudgetPopUp}
                              detailsBtnLabel={"projectVersion"}

        />
    )
}


export default ProjectMangerContainer;

