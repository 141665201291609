import React from "react";
import {Button, Spinner} from "reactstrap";
import {cloneOrgVersionManager, getBtnFunction, getColor, handleToggled, prepareValues, reopenOrgVersion} from "./genericListFunctions";
import styles from "../../GenericList/GenericList.module.css";
import {checkStatus, getImage} from "../functions";
import {Link} from "react-router-dom";
import {ITEM, MODELS} from "../../../navigation/Routes";
import NumberFormat from "react-number-format";
import {APPROVED, COMPLETED, SNAPSHOTTED} from "../constants";
import i18n from "i18next";

export function getCell(row) {
    return <div style={{marginTop: '7px'}}>{row.value}</div>;
}

export function getLeftAlignCell(row) {
    return <div style={{textAlign: 'left', marginTop: '7px'}}><span
        title={row.value}>{row.value}</span></div>;
}

export function getCenterAlignCell(row) {
  return <div style={{textAlign: 'center', marginTop: '7px'}}><span
    title={row.value}>{row.value}</span></div>;
}

export function getClickableCell(spinnerStatus, row, t, setValues, structureName, toggleCommentModalState) {
    return spinnerStatus ? <Spinner color={getColor(row.original.status)} size='sm'/> :
        <Button className={styles.circleBtn} title={t(row.original.status)}
                color={getColor(row.original.status)}
                onClick={() => {
                    setValues(prepareValues(row, structureName));
                    toggleCommentModalState(true)
                }}/>;
}

export function getImgCell(row) {
    return <div><img height={25} src={getImage(row.value)} alt={row.value} title={row.value}/></div>;
}

export function getLinkText(row) {
    return <div style={{textAlign: 'left', marginTop: '7px'}}><Link
        to={`${MODELS}/${row.original.subcategory_id}`}><span
        title={row.value}>{row.value}</span></Link></div>;
}

export function getUrlText(row) {
    return <div style={{textAlign: 'left', marginTop: '7px'}}><a
        href={`${row.value}`} target="_blank" rel="noreferrer">{row.value}</a></div>;
}

export function getClonedCell(row) {
    return <div><img height={25} src={getImage(row.value > 0 ? "Cloned" : "")} alt={row.value}/>
    </div>;
}

export function getNumberCell(row) {
    return <div style={{textAlign: 'right', marginTop: '7px'}}>
        <NumberFormat
            thousandSeparator={"."} prefix={'€ '} decimalSeparator={','}
            value={parseFloat(row.value)} decimalScale={2} fixedDecimalScale={true} displayType={'text'} disabled
        />
    </div>;
}

export function getPercentageCell(row) {
    return <div style={{marginTop: '7px'}}><NumberFormat suffix={'%'} value={row.value}
                                                         displayType={'text'} disabled/></div>;
}

export function getDeleteButton(isUploadEnabled, status, row, t, toggleConfModalState, setValues) {
    return <Button disabled={!isUploadEnabled || status === i18n.t(APPROVED) || checkStatus(status) || row.original.status === t(APPROVED)}
                   color={"danger"}
                   className={styles.btn}
                   onClick={() => {
                       toggleConfModalState(true);
                       setValues(row.original)
                   }}
    >Canc.
    </Button>;
}

export function getCloneButton(structureName, history, row, doCloneOrgVersionManager, t, isDisabled=false) {
    return (<Button color="success"  
                disabled={isDisabled || (structureName === "OrganizationVersion" && row.original.status !== t(COMPLETED))}
                onClick={() => {
                    structureName !== "OrganizationVersion" ? getBtnFunction(history, row) : cloneOrgVersionManager(doCloneOrgVersionManager, row);
                }}>{t("clone")}
            </Button>);
}

export function getReopenButton(structureName, history, row, doReopenOrg, t, isDisabled=false) {
    return (<Button color="success"  
                disabled={isDisabled}
                onClick={() => 
                    reopenOrgVersion(doReopenOrg, row)}>{t("reopen")}
            </Button>);
}

export function getUpdateButton(isUploadEnabled, status, row, t, structureName, toggleModalState, history, setValues) {
    return <Button disabled={!isUploadEnabled || status === i18n.t(APPROVED) || checkStatus(status) || row.original.status === t(APPROVED)}
                   className={styles.btn} onClick={() => {
        if (structureName !== "Model")
            toggleModalState(true);
        else
            history.push(`${ITEM}/${row.original.subcategory.id}`, {values: row.original});
        setValues(row.original)
    }}>Mod.</Button>;
}

export function getViewButton(structureName, toggleModalState, history, row, setValues) {
    return <Button color="info" className={styles.btn} onClick={() => {
        if (structureName !== "Model")
            toggleModalState(true);
        else
            history.push(`${ITEM}/${row.original.subcategory.id}`, {values: row.original, isDisabled: true});
        setValues({...row.original, isDisabled: true})
    }}>Dett.</Button>;
}

export function getErrorButton(row, secondLink) {
    return <Button color={row.original.isVerificationFailed ? "warning" : "info"}
                   onClick={() => secondLink(row.original.orgVersionId)
                   }>Verifiche</Button>;
}

export function getUserManageButton(row, toggleManageUserModalState) {
    if(!row.original.organization.isExternal) return null;
    return <Button color="info"
                   className={styles.btn}
                   onClick={() => toggleManageUserModalState(row.original.organization.instituteCode)
                   }>Utente</Button>;
}

export function getUnitsButton(row, secondLink, history) {
    return <Button color="info" 
                   onClick={() => secondLink(row.original.id, history)
                   }>Unità</Button>;
}


// Budgets - Button
export function getBudgetButton(row, toggleBudgetState, history) {
  return <Button color="info"
                 onClick={() => {toggleBudgetState(row.original.id)}
                 }>Budget{/*row.original.id*/}</Button>;
}

export function getCheckboxInput(row) {
    return <input
        className={styles.checkbox}
        readOnly
        type="checkbox"
        checked={row.original.active === true}
    />;
}

export const getCheckboxInputAccessor = (accessor, row) => {
    return <input
    className={styles.checkbox}
    readOnly
    type="checkbox"
    // eslint-disable-next-line eqeqeq
    checked={row.original[accessor] == 1 || row.original[accessor] === 'true' || row.original[accessor] === true}
/>;
}

export function getStatusBoxCell(selectedRows, row, setRows) {
    return <input
        type="checkbox"
        className={styles.checkbox}
        onChange={() => {
            handleToggled(selectedRows, row.original, setRows)
        }}
        checked={selectedRows.map(item => item.rowId).includes(row.original.rowId)}
    />;
}
