import {v4 as uuid} from "uuid";
import {calcTypes, DATE_FORMAT, totalsStructure, StructureMOHStructure, types, SNAPSHOTTED, SUBMITTED, VERIFIED, COMPLETED} from "./constants";
import warning from '../../assets/warning.png'
import error from '../../assets/error.png'
import success from '../../assets/success.png'
import cloned from '../../assets/cloned.png'
import i18n from "i18next";
import {isClonedColumn, statusColumn} from "./structureConstants";
import moment from "moment";
import {DOIMPORT, EMPTY, REQUESTFINANCIALSV2} from "../GraphQl/mutation";

export const duplicateResponse = (res) => {
    return res.map(item => {
        return {...item}
    })
}

export const keyValue = (input) => {
  
  console.log("");
  console.log("_______________");
  console.log("_Start-keyValue");
  console.log("_______________");
  Object.entries(input).forEach(([key,value]) => {
    
  console.log(key,value);
  
} );
  
  console.log("_______________");
  console.log("__Stop-keyValue");
  console.log("_______________");
  console.log("");
};

export const capitalize = (string) => {
  
  //return string;
  
  const words = string.split(" ");

  for (let i = 0; i < words.length; i++) {
    
    if (words[i] === undefined) continue;
    
    words[i] = words[i].toLowerCase();
    
    if (words[i][0] === undefined) continue;
    
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  
  }

  return words.join(" ");
  
}


export const getTableStructure = (structureName, data) => {
  
   
  
    if (structureName === "Financial") {
        const financialList = data ? JSON.parse(data.models[0].items) : [];
        // PM 20210421 non mostrare ReasonCode
        // return financialList ? [statusColumn, reasonCodeColumn, ...financialList] : []
        return financialList ? [statusColumn, ...financialList] : []
    } else if (structureName === "SubCategoryTotalDto") {
        return totalsStructure;
    } else if (structureName === "StructureMOHStructure") {
        return StructureMOHStructure;
    } else if (structureName === "Project") {
        const projectStructure = data ? duplicateResponse(data.__type.structure) : []
        return data ? [statusColumn, isClonedColumn, ...projectStructure] : [];
    } else if (structureName === "ProjectVersion") {
        var projectStructure = data ? duplicateResponse(data.__type.structure) : [];
        projectStructure = projectStructure.filter(d => d.Header !== 'versionType');
        return data ? projectStructure : [];
    } else {
        return data ? duplicateResponse(data.__type.structure) : [];
    }
};

export const getType = (col, structureName) => {
    switch (structureName) {
        case "Financial":
        case "SubCategoryTotalDto":
        case "StructureMOHStructure":
            return col.type.hasOwnProperty("name") ? col.type.name : col.type;
        case "Project":
        case "Category":
        case "SubCategory":
        case "Model":
        case "Structure":
        case "StructureSubcategory":
        case "ValidationRule":
        case "ReasonCode":
        case "ProjectVersion":
        case "OrganizationVersion":
        case "ProjectUnits":
            return col.type.name ? col.type.name : col.type.ofType.name;
        default:
            return []
    }
};

export const getVariables = (params) => {
    switch (params.structureName) {
        case "Financial":
            return {
                id: params.id ? params.id : params.values.model.id,
                orgVersion: params.ogrID ? params.ogrID : params.values.organizationVersion.id
            };
        case "SubCategory":
            return {id: params.id ? params.id : params.values.category.id};
        case "Model":
            return {id: params.id ? params.id : params.values.subcategory.id};
        case "ValidationRule":
        case "StructureSubcategory":
            return {structureId: params.structureId};
        case "SubCategoryTotalDto":
        case "Project":
        case "Category":
        default:
            return {};
    }
};

export const getMutationVariables = (id, ogrID, values, lastIndex, structureName, structureId, rowId = uuid()) => {
    const active = values ? values.active ? values.active.length : false : false;
    const isActive = typeof values.active === "boolean" ? values.active : active;

    switch (structureName) {
        case "Financial":
            return {
                modelId: id,
                orgVersionId: ogrID,
                rowId: rowId,
                recordItems: prepareValues(values, rowId, lastIndex).filter(items => items.accessor !== "status") // PM 20240918 non inviare lo status
            };
        case "StructureSubcategory":
            return {
                id: values.id ? values.id : null,
                data: {
                    structureId: structureId,
                    subcategoryId: parseInt(values.subcategory) ? values.subcategory : values.subcategory_id,
                    modelId: parseInt(values.model) ? values.model : values.model_id,
                    active: isActive,
                    numrow: values.numrow
                }
            };
        case "Category":
            return {
                id: values.id ? values.id : null,
                data: {
                    description: values.description,
                    active: isActive
                },
            };
        case "SubCategory":
        case "Model":
            return {
                id: values.id ? values.id : id,
                data: {
                    description: values.description,
                    active: isActive
                },
            };
        case "Structure":
            return {
                structureId: values.id ? values.id : null,
                data: {
                    description: values.description,
                },
            };
        case "ValidationRule":
            return {
                id: values.id ? values.id : null,
                structureId: structureId,
                data: {
                    message: values.message,
                    validationResult: values.validationResult,
                    expression: values.expression,
                    active: isActive
                }
            };
        case "ReasonCode":
            return {
                reasonCodeId: values.id ? values.id : null,
                reasonCode: {
                    code: values.code,
                    description: values.description
                }
            };     
        case "ProjectUnits":
            return {
                projectUnitId: values.id ? values.id : null,
                data: {
                    cup: values.cup,
                    coFinancing: values.coFinancing
                },
            };
        case "SubCategoryTotalDto":
        case "Project":
        default:
            return {};
    }
};

export const getDeleteVariables = (values, structureName, structureId) => {
        switch (structureName) {
            case "Financial":
                return {
                    modelId: values.model.id,
                    orgVersionId: values.organizationVersion.id,
                    rowId: values.rowId
                };
            case "Category":
                return {id: values.id};
            case "SubCategory":
                return {id: values.id};
            case "Structure":
                return {
                    id: values.id,
                    input: {description: ""}
                };
            case "StructureSubcategory":
                return {
                    structureId: parseInt(structureId),
                    id: values.id,
                };
            case "ValidationRule":
                return {
                    structureId: parseInt(structureId),
                    id: values.id,
                    data: {
                        message: values.message,
                        validationResult: values.validationResult,
                        expression: values.expression,
                        active: false
                    }
                };
            case "Model":
                return {modelId: values.id};
            case "ReasonCode":
                return {reasonCodeId: values.id};
            case "SubCategoryTotalDto":
            case "Project":
            default:
                return {};
        }
    }
;

function prepareValues(values, rowId, lastIndex) {
    const newValues = Object.entries(values).map(items => ({
        ...items,
        "rowId": rowId,
        "numrow": lastIndex
    })).map(({0: accessor, 1: value, ...rest}) => ({accessor, value, ...rest}));
    return newValues.map(items => {
        if (items.value === null) {
            items.value = ""
        }
        return items
    })
}

export const getImage = (type) => {
    switch (type) {
        case "ERROR":
            return error;
        case "WARNING":
            return warning;
        case "SUCCESS":
            return success;
        case "Cloned":
            return cloned;
        default:
            return ""
    }
};
export const getOptions = (name) => {
    return name === "Tipo" ? types.map(items => ({value: items, label: items})) : calcTypes.map(items => ({
        value: items,
        label: items
    }));
};

export const checkStatus = (status) => {
    switch (status) {
        case i18n.t(SUBMITTED):
        case i18n.t(VERIFIED):
        case i18n.t(COMPLETED):
        case i18n.t(SNAPSHOTTED):
        // case i18n.t("CONSOLIDATED"):
            return true;
        default:
            return false
    }
};


export const getImportMutation = (financialsApi) => {
    switch (financialsApi) {
        case "DOIMPORT":
            return DOIMPORT
        case "REQUESTFINANCIALSV2":
        case "REQUESTFINANCIALS2021":
            return REQUESTFINANCIALSV2
        default:
            return EMPTY;
    }
}

export const getModelId = (financialsApi) => {
    return financialsApi === "DOIMPORT" ? 30 : 51
}

export const getData = (data, result, structureName) => {
    let resultData;
    switch (structureName) {
        case "Financial":
            resultData = {
                ...data,
                result: [...data.result, ...Array.isArray(result) ? result : [result]]
            };
            break;
        case "Structure":
            resultData = {
                ...data,
                result: [...data.result, {...result, "validationRules": []}]
            };
            break;
        case "Category": {
            result.createdDate = moment(result.createdDate, DATE_FORMAT)
            result.lastModifiedDate = moment(result.lastModifiedDate, DATE_FORMAT)
            resultData = {
                ...data,
                result: [...data.result, result]
            };
            break;
        }
        default:
            resultData = {
                ...data,
                result: [...data.result, result]
            };
    }
  
  
    //console.log("RESULT DATA RESULT DATA RESULT DATA RESULT DATA RESULT DATA ")
    //console.log(resultData)
  
    return resultData
};
