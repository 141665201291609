import React, { useContext, useEffect} from 'react';
import GenericListContainer from "../GenericList/GenericListContainer";
import {GET_STRUCTURE_MOH, GET_STRUCTURE_QUERY} from "../GraphQl/query";
import {useQuery} from "@apollo/client";
import {duplicateResponse} from "../Helpers/functions";
import {PRJ, STRUCTUREYEARS} from "../../navigation/Routes";
import {ProjectContext} from "../ProjectProvider";
import {Spinner} from "reactstrap";


//TODO: ORIGINAL
const toOrganizationVersion = (structure, history) => {
  
    history.push({
        pathname: PRJ,
        state: {
            structureId: structure.id,
        }
    });
};

const countProjects = (projects, status) => {
    if (!projects)  return 0;
    let result = 0;
    projects.forEach(project => {
        project.projectVersions.forEach(version => {
            if (version.active) {
                result += version.organizationVersions.filter(org => org.status === status).length;
            }
        })
    });
    return result;
};

const sortStructures = (a, b) => a.description.localeCompare(b.description);

const calcTotals = data => data.map(structure => {
    structure.totalProjects = structure.projects.length;
    structure.approvedProjects = countProjects(structure.projects, 'APPROVED');
    structure.submittedProjects = countProjects(structure.projects, 'SUBMITTED');
    return structure;
});




const MOHPageContainer = () => {
  
  
    function to_structure_years(data) {
      
      let list = data.result;
      
      // console.log("list: ", list);
      
      let UserInstituteCode = undefined;
      
      let structureListReduced = new Array();
      
      let i = 0;
      let y = 0;
      let p = undefined;
      let structure_to_years = {};
      let years_set = undefined;
      let result_set = new Set();
      
      while (i < list.length) {
        
        structure_to_years = new Object();
        
        structure_to_years.structure_id = list[i].id;
        
        p = list[i].projects;
        
        //console.log("Projects From Structure " + list[i].id + ": ", p)
        
        years_set = new Set();
        
        y = 0;
        
        while (y < p.length) {
          
          
          // aggiungere l'anno solo se instituteCode
          // è quello dell'utente 
          
          //console.log("Structure: ", structure)
          
          if (UserInstituteCode > 0 || UserInstituteCode == undefined) {
            
            // se sono un utente normale (structure > 0) 
            
            // aggiungo l'anno solo se instituteCode
            // è quello dell'utente 
            
            
            if ((p[y].instituteCode == UserInstituteCode) || true) {
              
              
              //console.log("Normale Aggiungo Anno: ", p[y].year)
              years_set.add(p[y].year);
              
              
            }
            
            y++;
            
          } else {
            
            // altrimenti aggiungo tutti gli anni
            //console.log("Admin Aggiungo Anno: ", p[y].year)
            years_set.add(p[y].year);
            y++;
            
          }
          
        }
        
        //console.log("years_set: ", years_set);
        
        
        structure_to_years.years = years_set;
        
        //console.log("years_set.size: ", years_set.size);
        
        
        
        
        if (years_set.size > 0) {
          
          result_set.add(structure_to_years);
          
          structureListReduced.push(list[i]);
          
        }
        
        i++;
        
      }
      
      structureListReduced.sort((a, b) => a.description.toString().localeCompare(b.description.toString()));
      
      return Array.from(result_set);
      
      
    }
  
    const { setProject } = useContext(ProjectContext);
    useEffect(() => setProject(''), [setProject]);
    const {data, loading, error} = useQuery(GET_STRUCTURE_MOH);
  

    
    const {data: sq_data, loading: sq_loading, error: sq_error} = useQuery(GET_STRUCTURE_QUERY);
    
    
    
    
    if (loading) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
    if (error) return <div>{error.message}</div>;
  
    let years = to_structure_years(sq_data);
    
    //TODO: mancherebbe la lista degli anni
    //TODO: ATTENZIONE
    //TODO: PROVA MODIFICHE
    const anni = (id, desc, history) => {
      history.push({
        pathname: `${STRUCTUREYEARS}/${id}`,
        state: {
          "prj_desc": desc,
          "years": years,
        }
      });
    };
  
  
    const structureList = calcTotals(duplicateResponse(data.result).sort(sortStructures));
    return (
        <GenericListContainer list={structureList} structureName={"StructureMOHStructure"}
                              parentValue={"Cruscotto Approvazione Progetti"}
                              linkFunction={anni}
                              detailsBtnLabel={"Progetti"}

        />
    )
}


export default MOHPageContainer;

