/**
 * Form row input
 *
 * @see https://github.com/shoaibkhan94/reactstrap-formik
 */

import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { Col, FormFeedback, FormGroup, Label, Input} from 'reactstrap';
import moment from "moment";

import 'react-datepicker/dist/react-datepicker.css';

const setDate = (setStartDate,setFieldValue,fields) => {
    return date => {
        setFieldValue(fields.name, moment(date).hour(12).minute(0).second(0).millisecond(0));
        setStartDate(date)};
};

const RowDatePickerInput = ({
                                field: { ...fields },
                                form: { touched, errors, setFieldValue, values},
                                labelcols,
                                showuntouched,
                                label,
                                formGroupClass,
                                ...props
                            }) => {
    const date = moment(fields.value);
    const initDate = date.isValid()?new Date(fields.value):"";
    const [startDate, setStartDate] = useState(initDate);
    return (
        <FormGroup row className={formGroupClass}>
            <Label xs={labelcols} for={fields.name} className="label-color text-left">{label}</Label>
            <Col xs={12 - labelcols} className="text-right">
                <DatePicker
                    selected={startDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={setDate(setStartDate,setFieldValue, fields)}
                    {...props}
                />
                <Input hidden invalid={(touched[fields.name]) && errors[fields.name]}/>
                <FormFeedback >{errors[fields.name]}</FormFeedback>
            </Col>
        </FormGroup>
    )
};


RowDatePickerInput.propTypes = {
    field: PropTypes.object.isRequired, // da Formik: name
    form: PropTypes.object.isRequired,  // da Formik: touched, errors
    label: PropTypes.string.isRequired, // etichetta
    labelcols: PropTypes.number,        // num colonne per etichetta
    showuntouched: PropTypes.bool,       // mostra errore anche campo non touched (es. numerici)
    formGroupClass: PropTypes.string
};

RowDatePickerInput.defaultProps = {
    labelcols: 3,
    formGroupClass: "",
    showuntouched: false
};

export default RowDatePickerInput;
