import React, {useState} from 'react';
import {Field, Formik} from "formik";
import {useMutation, useQuery} from '@apollo/client'
import GenerikFormComponent from "./GenerikFormComponent";
import {withRouter} from "react-router-dom";
import {RowCheckInput} from "../FormElements";
import moment from "moment";
import {getMutation, getQuery, getUpdateMutation} from "../Helpers/mutation";
import {getData, getMutationVariables, getType, getVariables} from "../Helpers/functions";
import RowDatePickerInput from "../FormElements/RowDatePickerInput";
import CalculatedInput from "../FormElements/CalculatedInput";
import {withApollo} from '@apollo/client/react/hoc';
import RowSelectAutocompleteInput from "../FormElements/RowSelectAutocompleteInput";
import RowInput from "../FormElements/RowInput";
import {SETFINANTIALSTATUS} from "../GraphQl/mutation";
import {API_TYPES, TO_HIDE} from "../Helpers/constants";
import ApiSelectInput from "../FormElements/ApiSelectInput";
import {GET_UNITS_LIST} from "../GraphQl/query";
import * as Yup from 'yup';
import AutocompleteFromAPI from "../FormElements/AutocompleteFromAPI";
import {changeStatus} from "../Helpers/GenericListHelpers/genericListFunctions";

const GenericFormContainer = (props) => {

    const setStringFieldType = (isLongerString, disabled) => {
        if (!disabled) {
            return 'textarea'
        } else {
            return isLongerString ? 'textarea' : ""
        }
    };

    const generateFields = (structure, index, structureName, calculationFields, values) => {
        const type = getType(structure, structureName);
        const disabled = values ? values.isDisabled : false;
        const isLongerString = values[structure.accessor]?.length >= 45;
        const isVisibleStatus = disabled ? null : (structure.accessor === "status" || structure.accessor === "reasonCode") ? 'hidden' : null;
        const label = (structure.visible || isVisibleStatus) ? "" : structure.Header;
        let result;
        switch (type) {
            case "String":
                if (structure.accessor === "validationResult")
                    result = <Field key={index}
                                    className="form-control-sm" formGroupClass=" mb-1"
                                    label={structure.visible ? "" : structure.Header}
                                    list={["ERROR", "WARNING"]}
                                    type={"hidden"}
                                    disabled={disabled}
                                    name={structure.accessor} labelcols={6} component={RowSelectAutocompleteInput}
                    />;
                else result = <Field key={index} className="form-control-sm" formGroupClass=" mb-1"
                                     label={label}
                                     name={structure.accessor} labelcols={6} component={RowInput}
                                     hidden={structure.visible ? structure.visible : isVisibleStatus}
                                     disabled={disabled}
                                     type={setStringFieldType(isLongerString, disabled)}
                />;
                break;
            case "Integer":
            case "Int":
                if (!TO_HIDE.includes(structure.Header) && !(structureName === 'ProjectUnits' && structure.accessor === 'id'))
                    result = <Field key={index} className="form-control-sm" formGroupClass=" mb-1"
                                    label={structure.visible ? "" : structure.Header}
                                    name={structure.accessor} labelcols={6} component={CalculatedInput}
                                    calculation={calculationFields}
                                    type={structure.visible ? structure.visible : "number"}
                                    disabled={disabled}
                    />;
                break;
            case "Boolean":
                result =
                    <Field key={index} className="form-control-sm" label={structure.visible ? "" : structure.Header}
                           checkboxStyle={{marginLeft: 0}}
                           name={structure.accessor}
                        // type={structure.visible}
                           disabled={disabled || structure.accessor === 'previous_version'}
                           labelcols={6} component={RowCheckInput}/>;
                break;
            case "Date":
                if (structureName === "Financial") {
                    result = <Field key={index} className="form-control-sm" formGroupClass=" mb-1"
                                    label={structure.visible ? "" : structure.Header}
                                    type={structure.visible}
                                    disabled={disabled}
                                    name={structure.accessor} labelcols={6} component={RowDatePickerInput}/>;
                } else
                    result = <Field key={index} type="hidden"/>;
                break;
            case "BigDecimal":
            case "Currency":
                if (structure.hasOwnProperty('calculation')) {
                    result = <Field key={index} className="form-control-sm" formGroupClass=" mb-1"
                                    label={structure.visible ? "" : structure.Header}
                                    name={structure.accessor} labelcols={6}
                                    calculation={calculationFields}
                                    component={CalculatedInput} placeholder={structure.Header}
                                    disabled={true}
                                    type={structure.visible}
                    />;
                } else
                    result = <Field key={index} className="form-control-sm" formGroupClass=" mb-1"
                                    label={structure.visible ? "" : structure.Header}
                                    name={structure.accessor} labelcols={6}
                                    calculation={calculationFields}
                                    type={structure.visible ? structure.visible : "number"}
                                    disabled={disabled}
                                    component={CalculatedInput}/>;
                break;
            case "Dropdown":
                if (structure.hasOwnProperty('api')) {
                    result = structure.api !== "equipment" ? <ApiSelectInput
                        key={index} label={structure.visible ? "" : structure.Header}
                        list={structure.options}
                        name={structure.accessor}
                        type={"hidden"}
                        labelCols={6}
                        disabled={disabled}
                        className="form-control-sm"
                        inputProps={{defaultOption: values[structure.accessor]}}
                        structureName={structure.api}/> : <Field key={index}
                                                                 className="form-control-sm" formGroupClass=" mb-1"
                                                                 label={structure.visible ? "" : structure.Header}
                                                                 list={structure.options}
                                                                 type={"hidden"}
                                                                 disabled={disabled}
                                                                 structureName={structure.api}
                                                                 name={structure.accessor} labelcols={6}
                                                                 component={AutocompleteFromAPI}/>;



                } else {
                    result = <Field key={index}
                                    className="form-control-sm" formGroupClass=" mb-1"
                                    label={structure.visible ? "" : structure.Header}
                                    list={structure.options}
                                    type={"hidden"}
                                    disabled={disabled}
                                    name={structure.accessor} labelcols={6} component={RowSelectAutocompleteInput}
                    />;
                }
                break;
            case  "DropdownApi":
                result = structure.api !== "equipment"?<ApiSelectInput
                    key={index} label={structure.visible ? "" : structure.Header}
                    list={structure.options}
                    name={structure.accessor}
                    labelCols={6}
                    inputProps={{defaultOption: values[structure.accessor]}}
                    disabled={disabled}
                    className="form-control-sm"
                    structureName={structure.api}/>: <Field key={index}
                                                              className="form-control-sm" formGroupClass=" mb-1"
                                                              label={structure.visible ? "" : structure.Header}
                                                              list={structure.options}
                                                              type={"hidden"}
                                                              disabled={disabled}
                                                              structureName={structure.api}
                                                              name={structure.accessor} labelcols={6}
                                                              component={AutocompleteFromAPI}/>;
                break;
            case "SubCategory":
                result = <ApiSelectInput
                    key={index}
                    labelCols={6}
                    label={structure.visible ? "" : structure.Header}
                    list={structure.options}
                    type={"hidden"}
                    structureName={type}
                    inputProps={{defaultOption: values[structure.accessor]}}
                    disabled={disabled}
                    name={structure.accessor}/>
                break;
            case "Model":
                result = <ApiSelectInput
                    key={index}
                    labelCols={6}
                    label={structure.visible ? "" : structure.Header}
                    list={structure.options}
                    type={"hidden"}
                    structureName={type}
                    inputProps={{defaultOption: values[structure.accessor]}}
                    disabled={disabled}
                    name={structure.accessor}/>
                break;
            case "Organization":
                result = <Field key={index} className="form-control-sm" formGroupClass=" mb-1"
                                     label={label}
                                     name="organization.description" labelcols={6} component={RowInput}
                                     hidden={structure.visible ? structure.visible : isVisibleStatus}
                                     disabled
                                     type={setStringFieldType(false, disabled)}
                />;
                break;
            default:
                result = <Field key={index} type="hidden"/>

        }
        return result

    };

    const generateInitValue = (items, structureName) => {
        const type = getType(items, structureName);
        switch (type) {
            case "Date":
                return moment().hour(12).minute(0).second(0).millisecond(0);
            case "Int":
            case "BigDecimal":
            case "Integer":
                return 0;
            case "String":
                return "";
            case "Boolean":
                return false;
            default:
                return ""
        }
    };

    const validationSchema = Yup.object().shape({
        unit: Yup.string().required('Required'),
    });

    const prepareValues = (values, data, isFinancialPage) => {
        if (data && data.result && data.result.length > 0 ) {
            const apiName = API_TYPES.find(item => values.hasOwnProperty(item))
            switch (apiName) {
                case "unit":
                    values.unit = data.result.find(item => item.organization.description === values.unit)?.id
                    return values
                default:
                    return values
            }
        }
        if (isFinancialPage && !values.status) delete values.status;
        return values
    }

    const {label, structure, structureName, lastIndex, setValues, values} = props;
    const {data: unitsData} = useQuery(GET_UNITS_LIST);
    const toggleModal = props.toggleModal;
    const id = parseInt(props.match.params.id);
    const ogrID = parseInt(props.match.params.orgVersionId);
    const structId = parseInt(props.match.params.structureId);
    
    const calculationFields = structure.filter(item => item.hasOwnProperty('calculation'));
    
    const initValue = structure.map(items => items).reduce((obj, item) => {
        obj[item.accessor] = generateInitValue(item, structureName);
        return obj;
    }, {});
    const isFinancialPage = structureName === "Financial";
    const mutation = values ? getUpdateMutation(structureName) : getMutation(structureName);
    
    const [addRecord] = useMutation(mutation);
    // eslint-disable-next-line no-unused-vars
    const [spinnerStatus, setSpinnerStatus] = useState(false);
    const mutationName = mutation.definitions[0].name.value.toLocaleLowerCase()
    const isUpdateMutation = mutationName.includes("update");
    const [doApprove] = useMutation(SETFINANTIALSTATUS, {
        onCompleted(data) {
            setValues(null);
            setSpinnerStatus(false)
        }
    });
    // const preparedValue = prepareValues(values)
    
    const [modal, setModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    
    const toggle = () => {
      setModal((prevState) => !prevState);
    }
  
    return (
        <>
        <Formik
            validateOnChange={true}
            validateOnBlur={true}
            validationSchema={initValue.hasOwnProperty("unit") ? validationSchema : null}
            initialValues={{...initValue, ...values}}
            onSubmit={async (values, {setSubmitting}) => {
                setSubmitting(false);
                try {
                    let {comment, status, reasonCode, userComment = "", ...newVal} = values;
                    newVal = JSON.parse(JSON.stringify(values));
                    const valuesToSave = prepareValues(newVal, unitsData, isFinancialPage)
                    await addRecord({
                        variables: getMutationVariables(id, ogrID, valuesToSave, lastIndex, structureName, structId, values.rowId),
                        ...(!isUpdateMutation && {
                            update: (proxy, {data: {result}}) => {
                              
                                const data = proxy.readQuery({
                                    query: getQuery(structureName),
                                    variables: getVariables({
                                        id: id,
                                        structureName: structureName,
                                        ogrID: ogrID,
                                        structureId: structId
                                    })
                                });
                                proxy.writeQuery({
                                    query: getQuery(structureName),
                                    variables: getVariables({
                                        id: id,
                                        structureName: structureName,
                                        ogrID: ogrID,
                                        structureId: structId
                                    }),
                                    data: getData(data, result, structureName)
                                });
                            }
                        })
                    });

                    if (isFinancialPage && isUpdateMutation && status)
                        await changeStatus(setSpinnerStatus, doApprove, values.rowId, values.model.id, values.organizationVersion.id, "MODIFIED", comment, reasonCode, userComment)
                } catch (e) {
                  setErrorMessage(e.message);
                  toggle();
                } finally {
                    setValues(null);
                }
            }
            }>
            {
              (props) => (
                <>
                  <GenerikFormComponent {...props} label={label} structure={structure}
                                        structureName={structureName}
                                        toggleModal={toggleModal}
                                        calculationFields={calculationFields}
                                        generateFields={generateFields}
                                        setValues={setValues}
                  />
                </>
              )
            }
        </Formik>
          {
            modal &&
            <>
              <div>
                <br/>
                <br/>
              </div>
              <div>
                { errorMessage }
              </div>
            </>
          }
        </>
    )
};

export default withApollo(withRouter(GenericFormContainer))
