import React from 'react';
import GenericListContainer from "../GenericList/GenericListContainer";
import {useLocation, useParams, withRouter} from "react-router-dom";
import {
  PROJECTSBYSTRUCTUREYEAR,
} from "../../navigation/Routes";


const StructureListYearsContainer = (props) => {
  
    // Mostra tutti gli anni che hanno almeno un progetto di tipo 
    // specificato
  
    let location = useLocation();
  
    // Raccolgo il tipo del progetto
  
    const { structureId } = useParams();
    
    let state = location.state;
  
    let list = state.years;
    let prj_desc = state.prj_desc;
    
    let years = new Array();
    
    let i = 0;

    let structure_to_years_record = {};
    
    while (i < list.length) {
      
      if (list[i].structure_id === parseInt(structureId, 10) ) {
        
      for (const year of list[i].years) {
        
            structure_to_years_record = new Object();
            
            structure_to_years_record.id = list[i].structure_id;
            
            structure_to_years_record.description = year;
            
            years.push(structure_to_years_record);
          
        }
        
      }
      
      i++;
      
    }
  
  
    years.sort((a, b) => a.description.toString().localeCompare(b.description.toString()));
    //console.log("years: ", years);
    
  
    //  const sample = (id, year) => {
    //    props.history.push({
    //      pathname: `${SAMPLE}/${id}/${year}`,
    //      state: {
    //        a: "value",
    //        b: "value",
    //      }
    //    });
    //  };
  
    const projects_by_structure_year = (id, year) => {
      
        // console.log("projects_by_structure_year check structureId: ", id);
        // console.log("projects_by_structure_year check year: ", year);
      
        props.history.push({
        pathname: `${PROJECTSBYSTRUCTUREYEAR}/${id}/${year}`,
           state: {
              refetch: true,
              structureId: id,
              year: year,
              prj_desc: prj_desc
           }
          
        });
    
    };
  
    
    return (
        <GenericListContainer list={years} structureName={"Structure"}
                              detailsBtnLabel={"FiltraProgettiPerStrutturaAnno"}
                              linkFunction={projects_by_structure_year} secondLink={projects_by_structure_year}
                              secondDetailsBtn={"FiltraProgettiPerStrutturaAnno"}
                              btnLabel={"Create Structure"}
                              prj_desc={prj_desc}
        />
    )
  
};

export default withRouter(StructureListYearsContainer);
