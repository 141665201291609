import React, {useState} from 'react';
import {Col, Button, Form, Row, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {withApollo} from '@apollo/client/react/hoc';
import styles from "../Navbar/Navbar.module.css";


const GenericFormComponent = ({
                                  isSubmitting,
                                  handleSubmit,
                                  label,
                                  values,
                                  structure,
                                  generateFields,
                                  structureName,
                                  calculationFields,
                                  toggleModal,
                                  setValues
                              }) => {
  
    let fields = structure.map((items, index) => generateFields(items, index, structureName, calculationFields, values));
    fields = fields.filter(obj => obj.props.name !== "status");
    
    return (
      
      
        <>
          
          <div>
            <Form>
              {fields}
              <Row>
                <Col className="text-md-left pt-1 btn-confirm">
                  {<Button color="success" disabled={isSubmitting} hidden={values.isDisabled} onClick={handleSubmit}>{label}</Button>}
                </Col>
                <Col>
                  {<Button onClick={() => {
                    toggleModal(false);
                    setValues(null)
                  }}>Annulla</Button>}
                </Col>
              </Row>
            </Form>
          </div>
          
        
        </>
      

    )
};

export default withApollo(GenericFormComponent);
