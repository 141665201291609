import React, {Component} from 'react';

class Unauthorized extends Component{
    render(){
        return(
            <div>
                <h2>You must be authorized to access this content.</h2>
            </div>
        )
    }
}
export default Unauthorized;
