import React from 'react';
import { GET_STRUCTURE_QUERY} from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";
import {withRouter} from "react-router-dom";
import {RULES, STRUCTURESUBCATEGORIESLIST} from "../../navigation/Routes";
import {Query} from "@apollo/client/react/components";
import {duplicateResponse} from "../Helpers/functions";
import {Spinner} from "reactstrap";

const StructureList = (props) => {

  
    console.log("RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR");
    console.log("RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR");  
    
    const toStructureSubCategoryList = (id) => {
        props.history.push(`${STRUCTURESUBCATEGORIESLIST}/${id}`)
    };

    const toVerificationRules = (id) => {
        props.history.push(`${RULES}/${id}`)
    };
    const headers = ["structureSubcategories", "validationRules"];
    return (
        <Query query={GET_STRUCTURE_QUERY}>
            {({loading, error, data}) => {
                if (loading) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
                if (error) return error.message;
                const structureList = duplicateResponse(data.result)
                return (
                    <GenericListContainer list={structureList} structureName={"Structure"}
                                          detailsBtnLabel={"Sottocategorie"}
                                          linkFunction={toStructureSubCategoryList} secondLink={toVerificationRules}
                                          secondDetailsBtn={"Regole di validazione"}
                                          btnLabel={"Create Structure"} headers={headers}
                    />
                )
            }}
        </Query>
    )
};

export default withRouter(StructureList);
