import React, {useState} from 'react';
import {Formik} from 'formik';
import AuthFormComponent from "./AuthFormComponent";
import {useMutation, useApolloClient} from "@apollo/client";
import {GET_LOGIN_DATA} from "../GraphQl/query";
import {AUTH} from "../GraphQl/mutation";
import {useHistory} from "react-router-dom";
import {HOME, PRJS, PRJ, MOHPAGE, PROJECTSBYSTRUCTUREYEAR} from "../../navigation/Routes";
import {Alert, Container} from "reactstrap";
import {roles, MOH} from "../Helpers/constants";

const initialValues = {
    username: '',
    password: ''
};

// Scelta HOME PAGE
// TODO per MOH pagina con lista Structure e poi va a PRJ filtrata x structure (orgver->ver->proj->structure)
export const redirect = (history, userRoles, instituteCode, code) => {
  
    //TODO: ATTENZIONE
    if (userRoles.includes(MOH)) history.push(MOHPAGE);
    else if (roles.includes(userRoles)) {
    
      // Esce PROJECT per Utente Normale
      // Esce ADMIN per SuperUser
      
      // console.log("userRoles: ", userRoles);
      // console.log("instituteCode: ", instituteCode);
      
     
      
      // console.log("code: ", code);
      
      if ( userRoles !== "PROJECT")
        instituteCode = -1;
      
      if (typeof code !== 'undefined') {
        
        history.push({
          pathname: `${PROJECTSBYSTRUCTUREYEAR}/${code.id}/${code.year}`,
          state: {
            refetch: true,
            code: code.code,
          }
          
        });
        
      } else {
        
        
        history.push({
          pathname: `${PRJS}/${instituteCode}`,
          state: {
            refetch: true,
            userRoles: userRoles,
          }
          
        });
        
        
      }
      
    }
    //else if (roles.includes(userRoles)) history.push(PRJ);
    else history.push(HOME)
};

//TODO more than one role
export function getRole(auth) {
    if (auth.user.roles) {
        const length = auth.user.roles.length
        if(length <= 1){
            return auth.user.roles[0].role==='EXTERNAL'?'USER':auth.user.roles[0].role;
        }
        var r = auth.user.roles.find(item => {
            return (item.role !== "USER" && roles.includes(item.role)) || item.role==='EXTERNAL'
        });
        return r.role==='EXTERNAL'?'USER':r.role;
    }
}

const AuthFormContainer = () => {
    const history = useHistory();
    const client = useApolloClient();
    const [isAlertOpen, toggleAlert] = useState(false);
    const [color, setColor] = useState("");
    const [message, setMessage] = useState("");
    const [login] = useMutation(
        AUTH,
        {
            onCompleted(data) {
              
                localStorage.setItem('token', data.authenticate.token);
                let role = getRole(data.authenticate);
                client.writeQuery({
                    query: GET_LOGIN_DATA,
                    data: {
                        isLoggedIn: true,
                        userInfo: {...data.authenticate.user, role: role}
                    }
                });
                
              redirect(history, role);
              
            }
        }
    );

    return (

        <Container fluid>
            <Alert color={color} isOpen={isAlertOpen} toggle={() => toggleAlert(false)}>
                {`${message}`}
            </Alert>
            <Formik
                initialValues={{...initialValues}}
                onSubmit={async (values, {setSubmitting}) => {
                    setSubmitting(false);
                    localStorage.clear();
                    try {
                        await login({variables: {username: values.username, password: values.password}});
                    } catch (e) {
                        console.error(e);
                        setColor("danger");
                        setMessage("Errore: username o password non valido");
                        toggleAlert(true);
                        setTimeout(() => toggleAlert(false), 5000)

                    }
                }}>
                {(props) => (
                    <AuthFormComponent {...props}/>)
                }
            </Formik>
        </Container>)
};

export default AuthFormContainer

