import React from 'react';
import {Container, Modal} from "reactstrap";
import {Formik} from "formik";
import ReasonCodeModalFormComponent from "./ReasonCodeModalFormComponent";
import {commentModalInitValues, MODIFIED} from "../../Helpers/constants";


const getUploadedFile = (values, rowValues) => {
    if (values.file)
        return {fileName: values.file, fileDownloadUri: values.file};
    else
        return {fileName:rowValues.attachmentName, fileDownloadUri:rowValues.attachmentPath}
};

const ReasonCodeModal = ({
                             toggleModal,
                             isModalOpen,
                             rowValues,
                             changeStatus,
                             doApprove,
                             translate,
                             nestedModal,
                             setNestedModal,
                             closeAll,
                             setCloseAll,
                             setSpinnerStatus,
                             isCloned,
                             status,
                             userRole
                         }) => {

    const handleModalState = (state) => {
        toggleModal(state)
    };

    const toggleNested = () => {
        setNestedModal(!nestedModal);
        setCloseAll(false);
    };
    const toggleAll = () => {
        setNestedModal(!nestedModal);
        setCloseAll(true);
    };
    return (
        <Container fluid>
            <Modal isOpen={isModalOpen} toggle={() => toggleModal(false)} size='lg'>
                <Formik
                    initialValues={{...commentModalInitValues, ...rowValues}}
                    onSubmit={(values) => {
                        const status = (values.userComment && values.userComment !== rowValues.userComment)?MODIFIED:values.status;
                        changeStatus(setSpinnerStatus, doApprove, rowValues.rowId, rowValues.modelId, rowValues.orgVersionId,
                            status, values.comment, values.reasonCodeId, values.userComment, getUploadedFile(values,rowValues))
                    }}
                >
                    {props => (
                      
                        <ReasonCodeModalFormComponent {...props} translate={translate} toggleModal={handleModalState}
                                                      nestedModal={nestedModal} closeAll={closeAll}
                                                      toggleAll={toggleAll}
                                                      toggleNested={toggleNested} isCloned={isCloned} orgVersionStatus={status}
                                                      userRole={userRole}
                                                      readOnly={rowValues?.readOnly}
                                                      
                                                      />
                    )}
                </Formik>
            </Modal>
        </Container>
    )
};
export default ReasonCodeModal;
