/**
 * Form row input
 *
 * @see https://github.com/shoaibkhan94/reactstrap-formik
 */

import React from 'react';
import Select from 'react-select';

import {Col, FormFeedback, FormGroup, Label, Input} from 'reactstrap';
import {useQuery} from "@apollo/client";
import {GET_PRJ_INFO} from "../GraphQl/query";
import {getQuery} from "../Helpers/mutation";
import {duplicateResponse} from "../Helpers/functions";

const customCellStyle = {
    option: (provided, state) => ({
        ...provided,
        textAlign: 'left'
    }),
    singleValue: (styles, {data}) => ({...styles, lineHeight: 1.3, color: 'black'}),
};

const sortLabels = (firsLabel, secondLabel) => {
    const firsLabelToCompare = firsLabel.label.toUpperCase();
    const secondLabelToCompare = secondLabel.label.toUpperCase();
    if (firsLabelToCompare < secondLabelToCompare) {
        return -1;
    }
    if (firsLabelToCompare > secondLabelToCompare) {
        return 1;
    }
    return 0;
};

const renderSelectOptions = (data, loading, error, structureName) => {
    if (loading) return [{label: 'Loading...'}];
    if (error) return [{label: 'Error...'}];
    if (data) {
        if (structureName === "Model" || structureName === "SubCategory") {
            return data.map(({id, description}) => ({label: description, id: id}))
        } else if (structureName === "Unit") {
            return data.map(({id, description}) => ({label: description, id: id}))
        } else if (structureName === "equipment")
            return data.map(({code, description}) => ({label: `${code}: ${description}`, id: code}))
        else {
            data.map(items => (delete items.__typename));
            return data.map(items => ({
                label: structureName === "ReasonCode" ? `${items.code}: ${items.description}` : Object.values(items).join(' '),
                value: structureName === "ReasonCode" ? items.id : items
            })).sort((firsLabel, secondLabel) => {
                return sortLabels(firsLabel, secondLabel);
            });
        }
    }
};

const setDefaultData = (data, value) => {
    if (data) {
        data.map(items => (delete items.__typename));
        const result = data.find(item => item.id === value);
        const {id, code, description} = result ? result : {id: "", code: "", description: ""};
        return {label: `${code} ${description}`, value: id}

    }

};

const AutocompleteFromAPI = ({
                                 field: {...fields},
                                 form: {touched, errors, setFieldValue},
                                 labelcols,
                                 label,
                                 list,
                                 formGroupClass,
                                 onChange,
                                 defaultValue,
                                 disabled,
                                 structureName = "API",
                                 ...props
                             }) => {
    const query = getQuery(structureName)
    const {data: projectInfo} = useQuery(GET_PRJ_INFO);
    const {data, loading, error} = useQuery(query, {
        variables: {
            year: projectInfo?.year - 1,
            projectId: projectInfo?.projectId
        }
    });
    const duplicatedData = data ? duplicateResponse(data?.result) : []
    return (<FormGroup row className={formGroupClass}>
            <Label xs={labelcols} for={fields.name} className="label-color text-left">{label}</Label>
            <Col xs={12 - labelcols}>
                <Select styles={customCellStyle} isDisabled={disabled}
                        defaultValue={structureName === "ReasonCode" ? setDefaultData(duplicatedData, fields.value) : {
                            value: fields.value,
                            label: fields.value
                        }}
                        options={
                            renderSelectOptions(duplicatedData, loading, error, structureName)
                        }
                        onChange={value => {
                            switch (structureName) {
                                case "Model":
                                    setFieldValue("model", value.id);
                                    break;
                                case "SubCategory":
                                    setFieldValue("subcategory", value.id);
                                    break;
                                case "Unit":
                                    setFieldValue(fields.name, value.id);
                                    break;
                                case "ReasonCode":
                                    setFieldValue(fields.name, value.value);
                                    break;
                                case "equipment":
                                    setFieldValue(fields.name, value.id);
                                    break;
                                default: {
                                    setFieldValue(fields.name, value.value);
                                }
                            }
                        }}/>
                <Input hidden invalid={(touched[fields.name]) && errors[fields.name]}/>
                <FormFeedback>{errors[fields.name]}</FormFeedback>
            </Col>
        </FormGroup>
    );
};

export default AutocompleteFromAPI;
