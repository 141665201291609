import {gql} from '@apollo/client';

export const CREATE_CATEGORY_MUTATION = gql`
    mutation createCategory($data: CategoryInput!){
        result:createCategory(data:$data)
        {
            description active id lastModifiedDate createdDate
        }
    }`;

export const CREATE_SUBCATEGORY_MUTATION = gql`
    mutation CreateSubCategory
    ($id: Int!, $data: SubCategoryInput!)
    {
        result:createSubcategory
        ( categoryId: $id,
            data: $data) {
            id
            description
            active
            category {id description}
        }
    }`;

export const CREATE_MODEL_MUTATION = gql`
    mutation CreateModel
    ($id: Int!, $data: ModelInput!)
    {
        result:createModel
        ( subcategoryId: $id,
            data: $data) {
            id
            description
            active
            subcategory {id description}
        }
    }`;

export const UPDATE_CATEGORY = gql`
    mutation UpdateCategory
    ($id: Int!, $data: CategoryInput!)
    {
        updateCategory
        ( id: $id,
            data: $data) {
            id
            description
            active
            lastModifiedDate
            createdDate
        }
    }`;
export const UPDATE_SUBCATEGORY = gql`
    mutation UpdateSubCategory
    ($id: Int!, $data: SubCategoryInput!)
    {
        updateSubcategory
        ( id: $id,
            data: $data) {
            id
            description
            active
        }
    }`;

export const UPDATE_MODEL = gql`
    mutation UpdateModel
    ($id: Int!, $data: ModelInput!)
    {
        updateModel
        ( id: $id,
            data: $data) {
            id
            description
            active
            items
        }
    }`;

export const AUTH = gql`
    mutation Auth($username: String!, $password: String!)
    {
        authenticate(username: $username, password: $password)
        {
            token user {
            id, name, instituteCode, profileCode,surname
            roles {
                role
            }
        }
        }
    }`;

export const AUTHSSO = gql`
    mutation AUTHSSO($accessToken: String!)
    {
        authenticateSSO(accessToken: $accessToken)
        {
            token user {
            id, name, instituteCode, profileCode,surname
            roles {
                role
            }
        }
        }
    }`;

export const SAVEFINANCIAL = gql`
    mutation SaveFinancials
    ($modelId: Int!, $orgVersionId: Int!, $recordItems: [FinancialInput]!)
    {
        result:saveFinRecord(modelId:$modelId orgVersionId:$orgVersionId recordItems:$recordItems)
        {
            id
            rowId
            accessor
            value
            model {
                id description
            }
            organizationVersion {
                id active
                organization {
                    id
                }
            }
        }
    }`;

export const UPDATEFINANCIAL = gql`
    mutation updateFinRecord
    ($modelId: Int!, $orgVersionId: Int!, $rowId: String!, $recordItems: [FinancialInput]!)
    {
        updateFinRecord
        (modelId:$modelId, orgVersionId: $orgVersionId, rowId: $rowId, recordItems: $recordItems)
        {
            id
            rowId
            accessor
            active
            value
            model { id }
            organizationVersion { id }
        }
    }
`;

export const DELETE_FINANCIAL = gql`
    mutation DeleteFinancials
    ($modelId: Int!, $orgVersionId: Int!, $rowId:String!)
    {
        deleteFinRecord(modelId:$modelId orgVersionId:$orgVersionId rowId:$rowId)

    }`;

export const DELETE_CATEGORY = gql`
    mutation disableCategory ($id:Int!){
        disableCategory(id:$id){
                id, description, active 
        }
    }`;

export const DELETE_SUBCATEGORY = gql`
    mutation removeSubCategory
    ($id: Int!)
    {
        disableSubcategory(id:$id){id, description,active}
    }`;
export const DELETE_MODEL = gql`
    mutation disableModel
    ($modelId: Int!)
    {
        disableModel
        (id: $modelId)
        {
           id description  subcategory{id description} items active
        }
    }`;
export const uploadExcel = gql`
    mutation importReport($modelId:Int! $orgVersionId: Int! $reportInput: ReportInput) {
        importReport(modelId: $modelId, orgVersionId: $orgVersionId, reportInput: $reportInput)
    }`;

export const DOIMPORT = gql`
mutation requestFinancialsFromApi($modelId: Int!, $orgVersionId: Int!, $projectYear: Int!) {
        requestFinancialsFromApi(modelId: $modelId, orgVersionId:$orgVersionId, projectYear: $projectYear)
}`;
export const REQUESTFINANCIALSV2 = gql`
mutation requestFinancialsv2FromApi(
$modelId: Int!
$orgVersionId: Int!
$instituteCode: Long!
$projectCode: Int!) {
        requestFinancialsv2FromApi(modelId: $modelId, orgVersionId:$orgVersionId, instituteCode: $instituteCode, projectCode:$projectCode)
}`;

export const EMPTY = gql`
    mutation empty
    {empty}`;

export const VERIFY = gql`
    mutation verifyOrganizationVersion
($orgVersionId: Int)
{
    verifyOrganizationVersion
    (orgVersionId: $orgVersionId)
}`;

export const UPDATESTATUS = gql`mutation updateOrganizationVersionStatus
($orgVersionId: Int!, $newStatus: OrganizationVersionStatus!)
{
  updateOrganizationVersionStatus
  (orgVersionId: $orgVersionId, newStatus: $newStatus)
  {
    id
    active
    status
  }
}`;

export const SETFINANTIALSTATUS = gql`mutation saveFinantialsStatuses
($modelId: Int!, $organizationVersionId: Int!, $financialsStatuses: [FinancialsStatusInput]!)
{
    result:saveFinantialsStatuses
    (modelId:$modelId, organizationVersionId: $organizationVersionId, financialsStatuses:$financialsStatuses)
    {
        rowId
        comment
        status
        userComment
        attachmentName
        attachmentPath
        reasonCode{code description id}
        __typename  
    }
}`;

export const CREATEREASONCODE = gql`mutation saveReasonCode($reasonCodeId:Int, $reasonCode:ReasonCodeInput!)
{
    result:saveReasonCode(reasonCodeId:$reasonCodeId reasonCode:$reasonCode)
    {
        id
        code
        description
        __typename
    }
}`;
export const UPDATEREASONCODE = gql`mutation updateReasonCode($reasonCodeId:Int, $reasonCode:ReasonCodeInput!)
{
    result:saveReasonCode(reasonCodeId:$reasonCodeId reasonCode:$reasonCode)
    {
        id
        code
        description
        __typename
    }
}`;

export const DELETEREASONCODE = gql`
    mutation removeReasonCode
    ($reasonCodeId: Int!)
    {
        removeReasonCode
        (reasonCodeId: $reasonCodeId)
    }`;

export const CREATESUBCATEGORYCOMMENT = gql`mutation createSubcategoryComment($input:SubcategoryCommentInput!)
{
    result:createSubcategoryComment(input:$input)
    {
        id
        comment
        model{id}
        organizationVersion{id}
    }
}`;
export const UPDATESUBCATEGORYCOMMENT = gql`mutation createSubcategoryComment($commentId:Int! $input:SubcategoryCommentInput!)
{
    result:updateSubcategoryComment(input:$input subCategoryCommentId:$commentId)
    {
        id
        comment
        model{id}
        organizationVersion{id}
        }
    }`;

export const CREATE_STRUCTURE = gql`
    mutation createStructure($data:StructureInput!)
    {
        result:createStructure(structure:$data)
        {
            id description
        }
    }
`;

export const CREATE_STRUCTURE_SUBCATEGORY = gql`
    mutation createStructureSubcategory($data:StructureSubcategoryInput!)
    {
        result:createStructureSubcategory(structureSubcategory:$data)
        {
            id
            structure{description}
            subcategory{description}
            model{description}
            active
            numrow
        }
    }`;

export const CREATE_RULE = gql`
    mutation verificationRule($structureId:Int!,$data:ValidationRuleInput!)
    {
        result:createValidationRule(structureId:$structureId, validationRule:$data)
        {
            id
            message
            expression
            active
            validationResult
        }
    }
`;
export const DISABLE_STRUCTURESUBCATEGORY = gql`
    mutation disableStructureSubcategory ($id:Int){
        result:disableStructureSubcategory(id:$id){
            id
            structure{description}
            subcategory{description}
            model{description}
            active
        }
    }`;

export const UPDATE_STRUCTURE = gql`
    mutation updateStructure ($structureId:Int!, $data:StructureInput!){
    resul:updateStructure(id:$structureId,input:$data){
        id description
    }
}`;
export const DISABLE_STRUCTURE = gql`
    mutation updateStructure ($id:Int!, $input:StructureInput!){
    result:updateStructure(id:$id,input:$input){
        id description
    }
}`;

export const UPDATE_VALIDATION_RULE = gql`
    mutation updateValidationRule($structureId:Int,$data:ValidationRuleInput! $id:Int!)
{
    result:updateValidationRule(id:$id,structureId:$structureId, input:$data)
    {
        id
        message
        expression
        active
        validationResult
    }
}
`;
export const DISABLE_VALIDATION_RULE = gql`
    mutation updateValidationRule( $id:Int!,$data:ValidationRuleInput!)
{
    result:updateValidationRule(id:$id, input:$data)
    {
        id
        message
        expression
        active
        validationResult
    }
}
`;
export const UPDATE_STRUCTURESUBCATEGORY = gql`
        mutation updateStructureSubcategory($id:Int!, $data:StructureSubcategoryInput!){
            updateStructureSubcategory(id:$id,data:$data){
                id
                structure{description}
                subcategory{description}
                model{description}
                active
                numrow
            }
        }
`;

export const CLONEORGANIZATION = gql`mutation cloneOrganizationVersion($orgVersionId:Int!)
{
   result: cloneOrganizationVersion(parentOrgVersionId:$orgVersionId)

    {
        id
        active
        status
    }
}
`;
export const CONSOLIDATE_ORG_VERSION = gql`
    mutation consolidateOrganizationVersion($projectVersionId:Int!){
        result: consolidateOrganizationVersion(versionId:$projectVersionId)
        {
            id
        }
    }
`;
export const SNAPSHOT_ORG_VERSION = gql`
    mutation snapshotOrganizationVersion($projectVersionId:Int!){
        result: snapshotOrganizationVersion(versionId:$projectVersionId)
        {
            id
        }
    }
`;

// Create User and Update user
export const CREATE_USER = gql`
mutation createUser($data:UserInput!)
{
    result:createUser(user:$data)
    {
        id username email name surname
    }
}`;
                                                                                                                                                                                                                                                                                                 

export const UPDATE_USER = gql`
    mutation updateUser ($userId:Long!, $data:UserInput!){
    resul:updateUser(id:$userId,input:$data){
        id username email name surname
    }
}`;


export const DELETE_USER = gql`
    mutation removeUser
    ($modelId: Int!, $orgVersionId: Int!, $rowId:String!)
    {
        removeUser(modelId:$modelId orgVersionId:$orgVersionId rowId:$rowId)

    }`;

export const UPDATE_PROJECT_UNIT = gql`
mutation updateProjectUnit ($projectUnitId:Int!, $data:ProjectUnitsInput!){
resul:updateProjectUnit(projectUnitId:$projectUnitId,projectUnitInput:$data){
    id 
        organization{ 
            description 
            id 
            instituteCode 
            isExternal
        } 
        coFinancing cup
}
}`;

// Create Project Version and Update Project Version
export const CREATE_PROJECT_VERSION = gql`
mutation createProjectVersion($data:ProjectVersionInput!)
{
    result:createProjectVersion(data:$data)
    {
       id active description startPeriodDate endPeriodDate versionType
    }
}`;
                                                                                                                                                                                                                                                                                                 

export const UPDATE_PROJECT_VERSION = gql`
    mutation updateProjectVersion ($projectVersionId:Long!, $data:ProjectVersionInput!){
    resul:updateProjectVersion(id:$projectVersionId,input:$data){
        id active description endPeriodDate versionType
    }
}`;
// Create Project unit 
export const ADD_OPERATIVE_UNIT = gql`
mutation addOperativeUnit($data:ProjectUnitsInput!)
{
    result:addOperativeUnit(input:$data)
    {
        id coFinancing cup 
        project{
            id 
            code
        }
        organization{ 
            id 
            instituteCode 
            description 
            fiscalCode
            longDescription 
            legalRepresentativeName
            legalRepresentativeSurname
            isExternal
        } 
    }
}`;
                                                                                                                                                                                                                                                                                                 

// Create Project Version and Update Project Version
export const CREATE_ORGANIZATION = gql`
mutation createOrganization($data:OrganizationInput!)
{
    result:createOrganization(data:$data)
    {
       id instituteCode fiscalCode description longDescription legalRepresentativeName legalRepresentativeSurname isExternal
    }
}`;


export const UPDATE_PROJECT_BUDGETS = gql`
mutation updateProjectBudgets ($projectId: Int! $projectBudgetInputList: [ProjectBudgetsInput]!)
{
    result:updateProjectBudgets(
      projectId: $projectId
      projectBudgetInputList: $projectBudgetInputList
    ) {
      id
    }
}`;
  
