import React from "react";
import {APPROVED, INVESTIGATING, REJECTED, MODIFIED, CONSOLIDATED, OPENED, SUBMITTED, VERIFIED, SNAPSHOTTED, COMPLETED} from "./constants";
import i18n from "i18next"

export const defaultFilterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    const value = String(filter.value);
    return (row[id] !== undefined && row[id] !== null) ? String(row[id]).toLowerCase().includes(value.toLowerCase()) : false
};

// export const currencyFilterMethod =  (filter, row, column) => {
//     const id = filter.pivotId || filter.id;
//     const value = String(filter.value);
//     return (row[id] !== undefined && row[id] !== null) ? parseFloat(row[id]).toLocaleString('it-IT').toLowerCase().includes(value.toLowerCase()) : false
// };

export const currencyFilterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    const value = String(filter.value).replace(',', '.');
    if (filter.value.includes("<") && !filter.value.includes("|")) {
        const limit = value.replace("<","").trim();
        return (row[id] !== undefined && row[id] !== null) ? parseFloat(row[id]) <= parseFloat(limit) : false
    } else if (filter.value.includes(">") && !filter.value.includes("|")) {
        const limit = value.replace(">","").trim();
        return (row[id] !== undefined && row[id] !== null) ? parseFloat(row[id]) >= parseFloat(limit) : false
    } else if (filter.value.includes("|")) {
        const  rightLimit = filter.value.substr(filter.value.indexOf("|")+1).replace("<","").trim();
        const leftLimit = filter.value.substr(0,filter.value.indexOf("|")).replace(("<"),"").trim();
        return (row[id] !== undefined && row[id] !== null) ? parseFloat(leftLimit) <= parseFloat(row[id]) && parseFloat(row[id]) <= parseFloat(rightLimit) : false
    } else
        return (row[id] !== undefined && row[id] !== null) ? parseFloat(row[id]) === parseFloat(value) : false
};

export const statusFilterMethod = (filter, row) => {
    if (filter.value === "all") {
        return true;
    }
    if (filter.value === i18n.t('noStatus')) {
        return (row[filter.id] === null || row[filter.id] === undefined || row[filter.id] === "")
    }
    return row[filter.id] ? row[filter.id].toString() === filter.value : "";
};
export const BooleanFilterMethod = (filter, row) => {
    if (filter.value === "all") {
        return true;
    }
    return row[filter.id] !== null || row[filter.id] !== undefined ? row[filter.id].toString() === filter.value : "";
};

export const BooleanDefaultFilterMethod = (filter, row) => {
    if (!filter.value) {
        return true;
    }
    let val = row[filter.id] || '0';
    if (val === 'false') val = '0';
    if (val === 'true') val = '1';
    return val === filter.value;
};

export const statusFilter = ({filter, onChange}) =>
    <select
        onChange={event => onChange(event.target.value)}
        style={{width: "100%"}}
        value={filter ? filter.value : "all"}
    >
        <option value="all"/>
        <option value={i18n.t(APPROVED)}>{i18n.t('app')}</option>
        <option value={i18n.t(REJECTED)}>{i18n.t('rej')}</option>
        <option value={i18n.t(INVESTIGATING)}>{i18n.t('inv')}</option>
        <option value={i18n.t(MODIFIED)}>{i18n.t('mod')}</option>
        <option value={null}>{i18n.t('noStatus')}</option>
    </select>;

export const OrgStatusFilter = ({filter, onChange}) =>
    <select
        onChange={event => onChange(event.target.value)}
        style={{width: "100%"}}
        value={filter ? filter.value : "all"}
    >
        <option value="all"/>
        <option value={i18n.t(OPENED)}>{i18n.t('open')}</option>
        <option value={i18n.t(SUBMITTED)}>{i18n.t('conf')}</option>
        <option value={i18n.t(VERIFIED)}>{i18n.t('ver')}</option>
        <option value={i18n.t(APPROVED)}>{i18n.t('Approvato')}</option>
        <option value={i18n.t(SNAPSHOTTED)}>{i18n.t('Situazione corrente')}</option>
        <option value={i18n.t(CONSOLIDATED)}>{i18n.t('Consolidato')}</option>
        <option value={i18n.t(COMPLETED)}>{i18n.t('Completato')}</option>
    </select>;

export const BooleanFilter = ({filter, onChange}) =>
    <select
        onChange={event => onChange(event.target.value)}
        style={{width: "100%"}}
        value={filter ? filter.value : "all"}
    >
        <option value="all"/>
        <option value={"true"}>{i18n.t("active")}</option>
        <option value={"false"}>{i18n.t("inactive")}</option>
    </select>;

export const BooleanDefaultFilter = ({filter, onChange}) =>
    <select
        onChange={event => onChange(event.target.value)}
        style={{width: "100%"}}
        value={filter ? filter.value : ""}
    >
        <option value=""/>
        <option value={"1"}>Sì</option>
        <option value={"0"}>No</option>
    </select>;