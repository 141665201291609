
import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client';

import {GET_LOGIN_DATA} from "./query";
import {setContext} from "@apollo/client/link/context";

const apiConfig = process.env.REACT_APP_API_CONFIG;

export const DOWNLOAD_URL = `${apiConfig}/reports/download/`;


const httpLink = createHttpLink({
    uri: `${apiConfig}/graphql`,
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    assumeImmutableResults: false
});

client.cache.writeQuery({
    query: GET_LOGIN_DATA,
    data: {
        isLoggedIn: false,
        userInfo:{
            id: "",
            name: "",
            instituteCode: "",
            profileCode: "",
            role:"",
            __typename:"userInfo"
        }
    },
});
