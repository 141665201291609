export const setSortMethod = () => {
    return (a, b) => {
        a = (a === null || a === undefined || a === '') ? -Infinity : parseFloat(a);
        b = (b === null || b === undefined || b === '') ? -Infinity : parseFloat(b);

        if (a === b) {
            return 0;
        }
        return a > b ? 1 : -1;
    };
}
