const calcSum = (calculation, values) =>{
    return calculation.calculation.parameters.map(item => isNumber(values[item])).reduce((acc, red) => acc + red)
};
const calcSubtraction  = (calculation, values) =>{
    const res = calculation.calculation.parameters.map(item => isNumber(values[item])).reduce((acc, red) => acc - red)
    return res
};
export const  isNumber= num => isNaN(parseFloat(num)) ? 0 : parseFloat(num);

const calcPercentage = (calculation, values) => {
    return calculation.calculation.parameters.map(item => isNumber(values[item])).reduce((acc, red) => (acc * red) / 100);
};

const calcMultiply = (calculation, values) => {
    return  calculation.calculation.parameters.map(item => isNumber(values[item])).reduce((acc, red) => acc * red);
};

const calcMultiplyByRatio = (calculation, values) => {
    const valuesToMultiply = calculation.calculation.parameters.map(item => isNumber(values[item]));
    const valueToDivide =  valuesToMultiply.pop();
    return (valuesToMultiply.reduce((acc, red) => acc * red)) / isNumber(valueToDivide);
};
export const getFunctionResult = (calculation, values) => {

    switch (calculation.calculation.name) {
        case "sum":
            return calcSum(calculation, values);
        case "multiplyByRatio":
            return calcMultiplyByRatio(calculation, values);
        case "percentage":
            return calcPercentage(calculation, values);
        case "multiply":
            return calcMultiply(calculation, values);
        case "subtraction":
            return calcSubtraction(calculation, values);
        default:
            return 0
    }
};
