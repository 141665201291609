import React, {Component} from 'react';
import {GET_MODELS} from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";
import {withRouter} from "react-router-dom";
import {Query} from "@apollo/client/react/components";
import {duplicateResponse} from "../Helpers/functions";
import {Spinner} from "reactstrap";


class ModelListContainer extends Component {

    render() {
        const subCategoryId = parseInt(this.props.match.params.id);
        return (
            <Query
                query={GET_MODELS} variables={{id: subCategoryId}} fetchPolicy="network-only">
                {({loading, error, data}) => {
                    if (loading) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
                    if (error) return <div>{error.message}</div>;
                    const parentValue = data.result[0]?data.result[0].subcategory.description: "";
                    const modelList = duplicateResponse(data.result)
                    return (
                        <GenericListContainer list={modelList} structureName={"Model"} parentName={"SubCategory"}
                                              btnLabel={"Create Model"} parentValue={subCategoryId?parentValue:"Models"}/>
                    )
                }}
            </Query>
        )
    }
}

export default withRouter(ModelListContainer);
