/**
 * Form row input
 *
 * @see https://github.com/shoaibkhan94/reactstrap-formik
 */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {Col, FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import {getOptions} from "../Helpers/functions";


const customCellStyle = {
    option: (provided, state) => ({
        ...provided,
        textAlign: 'left'
    }),
    singleValue: (styles, {data}) => ({...styles, lineHeight: 1.3, color: 'black'}),
};

const seValues = (values, parentValue, dispatch, type, value) => {
    dispatch({"accessor": parentValue, "parameters": value.value, "name": type});
};

const DynamicSelectInput = ({
                                field: {...fields},
                                form: {touched, errors, setFieldValue, values},
                                labelcols,
                                label,
                                list,
                                formGroupClass,
                                onChange,
                                defaultValue,
                                disabled,
                                setParameters,
                                parentValue,
                                dispatch,
                                type,
                                ...props
                            }) =>
    <FormGroup row className={formGroupClass}>
        <Label xs={labelcols} for={fields.name} className="label-color text-left">{label}</Label>
        <Col xs={12 - labelcols}>
            {dispatch && <Select styles={customCellStyle} isDisabled={disabled}
                                 defaultValue={{value: fields.value, label: fields.value}}
                                 options={list ? list.map(items => ({value: items, label: items})) : getOptions(label)}
                                 onChange={value => {
                                     setFieldValue(fields.name, value.value);
                                     seValues(values, parentValue, dispatch, type, value)
                                 }}
            />}
            <Input hidden invalid={(touched[fields.name]) && errors[fields.name]}/>
            <FormFeedback>{errors[fields.name]}</FormFeedback>
        </Col>
    </FormGroup>;

DynamicSelectInput.propTypes = {
    field: PropTypes.object.isRequired, // da Formik: name
    form: PropTypes.object.isRequired,  // da Formik: touched, errors
    label: PropTypes.string.isRequired, // etichetta
    labelcols: PropTypes.number,        // num colonne per etichetta
    list: PropTypes.oneOfType([         // lista option: array di stringhe o di oggetti { id, desc }
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            desc: PropTypes.string
        }))
    ]),
    formGroupClass: PropTypes.string,
    disabled: PropTypes.bool
};

DynamicSelectInput.defaultProps = {
    labelcols: 3,
    formGroupClass: "",
    disabled: false
};

export default DynamicSelectInput;
