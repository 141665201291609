import React, {Component} from 'react';
import {Query} from "@apollo/client/react/components";
import {GET_LOGIN_DATA, getsubcategory2} from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";
import {withRouter} from "react-router-dom";
import {MODELS} from "../../navigation/Routes";
import i18n from "i18next"
import {duplicateResponse} from "../Helpers/functions";
import {Spinner} from "reactstrap";


class SubCategoriesList extends Component {

    toModelList = (id) => {
        this.props.history.push(`${MODELS}/${id}`)
    };

    toStructureSubcategory = (id) => {
        console.log(id);
    };

    render() {
        const headers = ["models", "structureSubcategories"];
        const categoryId = parseInt(this.props.match.params.id);
        return (
            <Query query={GET_LOGIN_DATA}>
                {({data: auth}) => (
                    <Query
                        query={getsubcategory2} variables={{id: categoryId}} fetchPolicy="network-only">
                        {({loading, error, data}) => {
                            if (loading) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
                            if (error) return <div>{error.message}</div>;
                            const parentValue = data.result[0]?data.result[0].category.description:"";
                            const subcategoryList = duplicateResponse(data.result)
                            return (
                                <GenericListContainer list={subcategoryList}
                                                      structureName={"SubCategory"}
                                                      parentName={"Category"}
                                                      parentValue={parentValue}
                                                      secondDetailsBtn={"To Structure"}
                                                      btnLabel={"Create SubCategory"} 
                                                      detailsBtnLabel={i18n.t("toModels")}
                                                      linkFunction={this.toModelList}
                                                      secondLink={this.toStructureSubcategory}
                                                      headers={headers}
                                />
                            )
                        }}
                    </Query>
                )}
            </Query>
        )
    }
}

export default withRouter(SubCategoriesList);
