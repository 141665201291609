import React, { useContext, useEffect } from 'react';
import GenericListContainer from "../GenericList/GenericListContainer";
import {FIND_PROJECT, GET_PRJ_VERSION_INFO, GET_PROJECT_VERSION, ORG_VERSION_MANAGER} from "../GraphQl/query";
import {useQuery, useApolloClient} from "@apollo/client";
import {duplicateResponse, keyValue} from "../Helpers/functions";
import {useLocation, useParams} from "react-router-dom";
import {SUBCATEGORIESTOTALS} from "../../navigation/Routes";
import {Spinner} from "reactstrap";


import {ProjectContext} from "../ProjectProvider";
const prepareOrgVersion = (list) => {
    return list.map(item => {
        const organization_desc = item.organization.description;
        const instCode = item.organization.instituteCode;
        const orgVersionId = item.id
        const organizationId = item.organization.id
        const internProjectId = item.version.project.id
        const projectVersionId = item.version.id
        const parent = item.parent
        const projectInfo = {...item.version.project}
        const structureInfo = {...item.version.project.structure}
        return {
            ...item, ...projectInfo, ...structureInfo,
            organization: organization_desc,
            projectVersionId: projectVersionId,
            orgVersionId: orgVersionId,
            instCode: instCode,
            parent: parent,
            organizationId: organizationId,
            internProjectId: internProjectId
        }
    })
}

const toSubCategoryList = (project, history) => {
    history.push({
        pathname: `${SUBCATEGORIESTOTALS}/${project.orgVersionId}/${project.structure.id}`,
        state: {
            status: project.status,
            isCloned: !!project.parent,
            projectCode: project.code,
            projectId: project.internProjectId,
            projectYear: project.year,
            externalProjectId: project.projectId,
            organizationId: project.organizationId,
            structureInfo: project.structure,
            orgVersionInstCode: project.instituteCode,
            instituteDescription: project.organization,
            coFinancing: project.coFinancing
        }
    })
};

const filterOrgVersionList = (list) => {
    const groupedByOrg = list.reduce(function (accumulator, currentItem) {
        let key;
        // ignore already consolidated and verified projects
        if (currentItem.status === 'VERIFIED' ) {
            key = `v__${currentItem["organization"]}`;
        } else if (currentItem.status === 'SNAPSHOTTED') {
            key = `s__${currentItem["organization"]}`;
        } else {
            key = currentItem["organization"];
        }
        if (!accumulator[key]) {
            accumulator[key] = []
        }
        accumulator[key].push(currentItem)
        return accumulator
    }, {})
    
    const filtered = Object.values(groupedByOrg).map(item => item.reduce(function (r, a) {
        return r.lastModifiedDate > a.lastModifiedDate ? r : a
    })).sort((a, b) =>  b.status.toUpperCase().localeCompare(a.status.toUpperCase()));

    return filtered;
}

const OrgVersionContainer = () => {
  
    const { setProject } = useContext(ProjectContext);
    
    useEffect(() => setProject(''), [setProject]);

    const {projectVersionId} = useParams();
  
    //console.log("[[]] :", projectVersionId);
    
    const {loading: p_loadingProject, data: p_dataProject, error: p_errorProject} = useQuery(FIND_PROJECT, {
      variables: { projectVersionId },
    });
    
    //let missingheader2;
    let missingheader;  
  
    if (p_dataProject !== undefined &&
      p_dataProject.hasOwnProperty('result') &&
      p_dataProject.result !== undefined &&
      p_dataProject.result.hasOwnProperty('code') &&
      p_dataProject.result.hasOwnProperty('instituteDescription')) {
      
      missingheader = p_dataProject.result.code + "\n" + p_dataProject.result.instituteDescription;
      
    }
    
    const {state} = useLocation();
    
    const client = useApolloClient()
    const {data, loading, error} = useQuery(ORG_VERSION_MANAGER, {
        fetchPolicy: 'network-only',
        variables: {projVersion: parseInt(projectVersionId)}
    });
    const {data: prVersionInfo} = useQuery(GET_PRJ_VERSION_INFO)
     
    const {projectVersionDesc, hasUnits} = state ? state : prVersionInfo
    if (loading) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
    if (error) return <div>{error.message}</div>;
    if (state?.from) {
        client.writeQuery({
            query: GET_PRJ_VERSION_INFO,
            data: {
                projectVersionDesc: projectVersionDesc,
                hasUnits: hasUnits,
            }
        });
    }
    const list = duplicateResponse(data?.organizationVersionsManager)
    const orgVersionList = prepareOrgVersion(list)
    const sortedOrgVersionList = orgVersionList.sort(function (a, b) {
        if (a.organization < b.organization) {
            return -1;
        }
        if (a.organization > b.organization) {
            return 1;
        }
        return 0;
    })
    const filteredByDateList = filterOrgVersionList(sortedOrgVersionList);
  
    missingheader = filteredByDateList[0].code + "\n" + filteredByDateList[0].instituteDescription;
    
  
    
    return (
        <GenericListContainer list={filteredByDateList} structureName={"OrganizationVersion"}
                              parentValue={projectVersionDesc} isManagerHasUnit={hasUnits}
                              linkFunction={toSubCategoryList}
                              detailsBtnLabel={"Categorie"}
                              missingheader={missingheader}
        />
    )
}


export default OrgVersionContainer;

