import React from 'react';
import GenericListContainer from "../GenericList/GenericListContainer";
import {FIND_PROJECT, GET_PROJECT_VERSION} from "../GraphQl/query";
import {useQuery} from "@apollo/client";
import {duplicateResponse, keyValue} from "../Helpers/functions";
import {useParams, useLocation} from "react-router-dom";
import {ORGVERSION} from "../../navigation/Routes";
import {Spinner} from "reactstrap";

const toOrgVersion = (projectVersionId, history, project) => {
  
  
    //keyValue(project);
    
    history.push({
        pathname: `${ORGVERSION}/${projectVersionId}`,
        state: {
            projectVersionDesc: project.description,
            hasUnits: project.project.structure.hasUnits,
            from:"prjVersion"
        }
    })
};

const ProjectVersionContainer = () => {
    const {projectId} = useParams();
  
    //const {loading: p_loadingProject, data: p_dataProject, error: p_errorProject} = useQuery(FIND_PROJECT, {
       //variables: { projectId },
    //});
     
    const {state} = useLocation();
    const  projectCode = state?.projectCode;
    const  developer = state?.developer;
    const  missingheader = state?.missingheader;
    
    const  project = state?.project;
    
    //console.log("ho ricevuto come project ", project);
    
    const {data, loading, error} = useQuery(GET_PROJECT_VERSION, {
        variables: {projectId: projectId}
    });
    if (loading) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
    if (error)  return <div>{error.message}</div>;
    const projectVersionList = duplicateResponse(data.result)
  
    //keyValue(p_dataProject);
  
    return (
        <GenericListContainer list={projectVersionList} 
                              structureName={"ProjectVersion"}
                              parentValue={projectCode}
                              linkFunction={toOrgVersion}
                              detailsBtnLabel={"orgVersion"}
                              developer={developer}
                              missingheader={missingheader}
                              project={project}

        />
    )
}


export default ProjectVersionContainer;

