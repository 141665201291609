import React, {Component} from "react";
import {withRouter} from "react-router-dom";

class OrganizationList extends Component {
    render() {
        return(
            <div>
                thi is oraganization page
            </div>
        )
    }
}

export default withRouter(OrganizationList)
