/* eslint-disable no-unused-vars */
import React, {useEffect, useState, useCallback, useRef} from 'react';
import {Spinner, Container} from "reactstrap";
import {useMutation, useApolloClient, useQuery} from "@apollo/client";
import {AUTHSSO} from "../GraphQl/mutation";
import {GET_LOGIN_DATA, CODEDETAILS} from "../GraphQl/query";
import {redirect, getRole} from "../AuthForm/AuthFormContainer";
import {useHistory, withRouter} from "react-router-dom";
import {LOGIN} from "../../navigation/Routes";


// development auth bypass
const env = process.env.NODE_ENV || 'development';
const devToken = env === 'development' ? process.env.REACT_APP_TOKEN : undefined;
// end development auth bypass

const SSOAUTH = (props) => {
  
 
  
  
  let [loaderState, setLoaderState] = useState(true);
  
  const history = useHistory();
  
  // l'unico modo con cui riesco a leggere
  // i parametri
  const pathParts = props.location.pathname.split('/');
  const token = pathParts[2];
  let code = pathParts[3];
  
  // Test
  // console.log("token", token);
  // console.log("code", code);
  
  const client = useApolloClient();
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('token');
  
  // Test
  // code = "RF-2016-02361926";
  
  const [ssoAuth] = useMutation(AUTHSSO, {
    onCompleted(data) {
      
      
     
      
      
      // setLoaderState(true);
      
      // console.log("data.authenticateSSO.token: ", data.authenticateSSO.token);
      
      localStorage.setItem('token', data.authenticateSSO.token);
      
      // let role = data.authenticateSSO.user.roles.find(item => roles.includes(item.role));
      
      let role = getRole(data.authenticateSSO);
      
      client.writeQuery({
        query: GET_LOGIN_DATA,
        data: {
          isLoggedIn: true,
          userInfo: {...data.authenticateSSO.user, role: role}
        }
      });
      
      // console.log("CODEDETAILS PAR: ", code ? code : "undefined");
      
      client.query({
        query: CODEDETAILS,
        variables: {
          code: code ? code : "undefined"
        }
      })
      .then(result => {
        
        let code_details = {
          
          code: code,
          id: undefined,
          year: undefined
          
        };
        
        let codedetailsdata = result.data;
        
        if (codedetailsdata !== undefined && Array.isArray(codedetailsdata.result) && codedetailsdata.result.length === 1) {
          
          
          code_details.id = codedetailsdata.result[0].structure.id;
          code_details.year = codedetailsdata.result[0].year;
          
        } else {
          
          code_details = undefined;
          
        }
        
        // console.log("code_details: ", code_details);
        
    
        
        redirect(history, role, data.authenticateSSO.user.instituteCode, code_details);
        
      })
      .catch(error => {
        
      
        
        console.error('CODEDETAILS ERROR: ', error);
        
      });
      
    }
  });
  
  const callAuthSSO = useCallback(async () => {
      localStorage.clear();
      try {
        //setLoaderState(true);
        
        //await client.resetStore();
        await client.cache.reset();
        
        let accessToken;
        if (devToken) accessToken = devToken;
        else accessToken = token ? token : myParam;

        // console.log("chiamo ssoAuth con token: ", accessToken);
        
        await ssoAuth({variables: { accessToken }});
        
      } catch (error) {
        console.log("call AuthSSO error: ", error);
        //setLoaderState(false);
        history.push(LOGIN);
      }
      
    },
    [history, client, myParam, ssoAuth]);

  
  useEffect(() => {
    async function executeAuthSSO() {
      await callAuthSSO();
    }
    
    executeAuthSSO();
  }, [callAuthSSO]);
  
  //useEffect(() => () => setLoaderState(true), []);
  
   return (
     <Container>
       {true && <Spinner style={{width: '3rem', height: '3rem'}}/>}
     </Container>
   )
  
  
  
}

export default withRouter(SSOAUTH)
