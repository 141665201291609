/**
 * Form row input
 *
 * @see https://github.com/shoaibkhan94/reactstrap-formik
 */

import React from 'react';
import PropTypes from 'prop-types';

import {Col, FormFeedback, FormGroup, Input, Label} from 'reactstrap';

const setValuesInState = (values, parentValue, dispatch, type) => {
    const optionsArray = values.items.map(item=>item.options);
    dispatch({"accessor":parentValue, "options":optionsArray,  "type":type});
};

const DynamicRowInput = ({
                      field: {...fields},
                      form: {touched, errors, values},
                      maxLength,
                      labelcols,
                      showuntouched,
                      label,
                      labelClass,
                      formGroupClass,
                      parentValue,
                      dispatch,
                      type,
                      ...props
                  }) =>
    <FormGroup row className={formGroupClass}>
        <Label xs={labelcols} for={fields.name} className={`label-color text-left ${labelClass}`}>{label}</Label>
        <Col xs={12 - labelcols}>
            <Input {...props} {...fields} invalid={(showuntouched || touched[fields.name]) && !!errors[fields.name]}
                   maxLength={maxLength} onBlur ={() => setValuesInState(values, parentValue, dispatch, type)}/>
            <FormFeedback>{errors[fields.name]}</FormFeedback>
        </Col>
    </FormGroup>;

DynamicRowInput.propTypes = {
    field: PropTypes.object.isRequired, // da Formik: name
    form: PropTypes.object.isRequired,  // da Formik: touched, errors
    label: PropTypes.string.isRequired, // etichetta
    labelcols: PropTypes.number,        // num colonne per etichetta
    showuntouched: PropTypes.bool,       // mostra errore anche campo non touched (es. numerici)
    formGroupClass: PropTypes.string,
    maxLength: PropTypes.number
};

DynamicRowInput.defaultProps = {
    labelcols: 3,
    formGroupClass: "",
    showuntouched: false,
    maxLength: null
};

export default DynamicRowInput;


