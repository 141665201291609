import React from 'react';
import { GET_STRUCTURE_QUERY} from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";
import {useLocation, useParams, withRouter} from "react-router-dom";
import {
  FINANCIAL,
  PRJVERSION,
  RULES,
  STRUCTURESUBCATEGORIESLIST,
  STRUCTUREYEARS,
  SUBCATEGORIESTOTALS
} from "../../navigation/Routes";
import {Query} from "@apollo/client/react/components";
import {duplicateResponse} from "../Helpers/functions";
import {Spinner} from "reactstrap";


const TipologieProgetto = (props) => {
  
  
    let userRoles = props.location.state?.userRoles;
    
    
    // console.log("userROLES: ", userRoles);
    
  
    let UserInstituteCode;
    
    let {instituteCode} = useParams();
  
    UserInstituteCode = parseInt(instituteCode);
  
    // let location = useLocation();
    
    // let state = location.state;
    
    // let structure  = state.structure_id;
    
    let structureListReduced = new Array();
    
    return (
      
        <Query query={GET_STRUCTURE_QUERY}>
            {({loading, error, data}) => {
              
              if (loading) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
              if (error) return error.message;
              const structureList = duplicateResponse(data.result)
              
              const list = structureList;
              
              //console.log("Props: ", props)
              
              //console.log("User Structure Id: ", structure)
              
              //console.log("Structure List: ", list)
              
              let i = 0;
              let y = 0;
              let p = undefined;
              let structure_to_years = {};
              let years_set = undefined;
              let result_set = new Set();
              
              while (i < list.length) {
                
                structure_to_years = new Object();
                
                structure_to_years.structure_id = list[i].id;
                
                p = list[i].projects;
                
                //console.log("Projects From Structure " + list[i].id + ": ", p)
                
                years_set = new Set();
                
                y = 0;
                
                while (y < p.length) {
                  
                  
                  // aggiungere l'anno solo se instituteCode
                  // è quello dell'utente 
                  
                  //console.log("Structure: ", structure)
                  
                  //if (UserInstituteCode > 0) {
                  
                    if (userRoles === "PROJECT") {
                      // TODO: ENTRARE SOLO SE è UNA UTENZA NORNALE
                    
                      // se sono un utente normale (structure > 0) 
                    
                      // aggiungo l'anno solo se instituteCode
                      // è quello dell'utente 
                    
                      
                      // if ((p[y].instituteCode == UserInstituteCode)) {
                        
                        
                        //console.log("Normale Aggiungo Anno: ", p[y].year)
                        years_set.add(p[y].year);
                       
                    
                    //}
                    
                    y++;
                    
                  } else {
                    
                    // altrimenti aggiungo tutti gli anni
                    //console.log("Admin Aggiungo Anno: ", p[y].year)
                    years_set.add(p[y].year);
                    y++;
                  
                  }
                  
                }
                
                //console.log("years_set: ", years_set);
                
                
                structure_to_years.years = years_set;
                
                //console.log("years_set.size: ", years_set.size);
                
                
                
                
                if (years_set.size > 0) {
                  
                  result_set.add(structure_to_years);
                  
                  structureListReduced.push(list[i]);
                  
                }
                
                i++;
                
              }
              
              structureListReduced.sort((a, b) => a.description.toString().localeCompare(b.description.toString()));
              

              const years = Array.from(result_set);
              
              // console.log("years: ", years);
              
              
              
              const anni = (id, desc) => {
                props.history.push({
                  pathname: `${STRUCTUREYEARS}/${id}`,
                  state: {
                    "prj_desc": desc,
                    "years": years,
                  }
                });
              };
              
              
              return (
                  <GenericListContainer list={structureListReduced} structureName={"Structure"}
                                        detailsBtnLabel={"Filtra"}
                                        linkFunction={anni} secondLink={anni}
                                        secondDetailsBtn={"Filtra"}
                                        btnLabel={"Create Structure"}
                  />
                )
            }}
          
        </Query>
    )
};

export default withRouter(TipologieProgetto);
