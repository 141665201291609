import {Field, FieldArray, Form} from "formik";
import {Button, Col, Container, Row} from "reactstrap";
import RowInput from "../FormElements/RowInput";
import RowSelectAutocompleteInput from "../FormElements/RowSelectAutocompleteInput";
import {RowCheckInput} from "../FormElements";
import DynamicInput from "../FormElements/DynamicInput";
import React from "react";
import i18n from "i18next"
import AutocompleteFromAPI from "../FormElements/AutocompleteFromAPI";
import styles from "./ModelItemStyle.module.css"
import FormSelectInput from "../FormElements/SelectInput";
import {types} from "../Helpers/constants";

const emptyItem =
    [{
        "Header": "",
        "accessor": "",
        "type": "",
        "calc": "",
        "calculation_type": "",
        "api": "",
    }];
const ModelNewItemFormComponent = (values, dispatch, setCalculationOptions, initItems, toggleModal, setStructure, state, t, isDisabled, isCloneAction, subcategoryId) => {
    return (
        <Container>
            <Form>
                <Row>
                    <Col xs={9} className="text-left">
                        <h1>{i18n.t("saveModel")}</h1>
                    </Col>
                    <Col xs={3}>
                        <Button color={"info"} onClick={() => {
                            toggleModal(true);
                            setStructure(values)
                        }}>{i18n.t("preview")}</Button>{" "}
                        <Button disabled={isDisabled} color={"success"} type="submit">{i18n.t("submit")}</Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Field label="Descrizione" name="description"
                               component={RowInput} disabled={isDisabled}/>
                    </Col>
                    {(isCloneAction || isDisabled || isNaN(subcategoryId)) && <Col xs={12}>
                        <Field label="Subcategory" name="subCategory" labelcols={3}
                               component={AutocompleteFromAPI} disabled={isDisabled} structureName={"SubCategory"}/>
                    </Col>}
                    <Col xs={12}>
                        <Field label="Attivo" name="active" itemsFormCheckbox={styles.itemsFormCheckbox}
                               component={RowCheckInput} disabled={isDisabled}/>
                    </Col>
                    <Col xs={12}>
                        <Field label="uploadEnabled" name="uploadEnabled" itemsFormCheckbox={styles.itemsFormCheckbox}
                               component={RowCheckInput} disabled={isDisabled}/>
                    </Col>
                </Row>
                <FieldArray
                    name="initItems"
                    render={arrayHelpers => (
                        <div>
                            <Col>
                                <h4>{i18n.t("items")}</h4>
                            </Col>
                            {
                                values.initItems.map((initItems, index, array) => (
                                        <div key={index}>
                                            <Field label="Intestazione" name={`initItems.${index}.Header`}
                                                   component={RowInput} disabled={isDisabled}/>
                                            <Field label="Accessor" name={`initItems.${index}.accessor`}
                                                   component={RowInput} disabled={isDisabled}/>
                                            <FormSelectInput label="Tipo" name={`initItems.${index}.type`}
                                                   inputProps ={{defaultOption: array[index].type, options:types}}
                                                    disabled={isDisabled}/>
                                            {initItems.type === "BigDecimal" &&
                                            <Row>
                                                <Col xs={12}>
                                                    <Field name={`initItems.${index}.calc`} label="Formula"
                                                           component={RowCheckInput} disabled={isDisabled}
                                                           itemsFormCheckbox={styles.itemsFormCheckbox}/>
                                                </Col>
                                            </Row>}
                                            {(initItems.type === "Dropdown" && !array[index].api) &&
                                            <Row>
                                                <Col>
                                                    <DynamicInput type={initItems.type} options={initItems.options}
                                                                  parentValue={initItems.accessor} dispatch={dispatch}
                                                                  disabled={isDisabled}/>
                                                </Col>
                                            </Row>
                                            }
                                            {(initItems.type === "DropdownApi" || array[index].api) &&
                                            <Field label="Nome API" name={`initItems.${index}.api`}
                                                   component={RowInput} disabled={isDisabled}/>
                                            }
                                            {initItems.calc &&
                                            <div><Field label="Tipo Formula"
                                                        name={`initItems.${index}.calculation_type`}
                                                        component={RowSelectAutocompleteInput} disabled={isDisabled}/>
                                                <Col md={{size: 8, offset: 3}}><p>{t("calcWarning")}</p></Col>

                                            </div>

                                            }
                                            {initItems.calculation_type &&
                                            <Row>
                                                <Col>
                                                    <DynamicInput
                                                        isDisabled={isDisabled}
                                                        list={setCalculationOptions(values)}
                                                        dispatch={dispatch}
                                                        options={initItems?.calculation?.parameters}
                                                        type={initItems.calculation_type}
                                                        parentValue={initItems.accessor}
                                                    />
                                                </Col>
                                            </Row>
                                            }
                                            <Col>
                                                <Button disabled={isDisabled} color="danger" type="Button"
                                                        onClick={() => arrayHelpers.remove(index)}>{i18n.t("remove")}</Button>
                                                {" "}
                                                <Button disabled={isDisabled} hidden={index !== array.length - 1}
                                                        type="Button"
                                                        color="success"
                                                        onClick={() => arrayHelpers.push(emptyItem[0])
                                                        }>{i18n.t("add")}</Button>{" "}
                                                <Button disabled={isDisabled}
                                                        hidden={index === 0}
                                                        type="Button"
                                                        onClick={() => arrayHelpers.swap(index, index-1)
                                                        }>{i18n.t("move_up")}
                                                </Button>{" "}
                                                <Button disabled={isDisabled}
                                                        type="Button"
                                                        hidden={index === array.length - 1}
                                                        onClick={() => {
                                                            arrayHelpers.swap(index, index + 1)
                                                        }
                                                        }>{i18n.t("move_down")}</Button>
                                            </Col>
                                            <hr style={{backgroundColor: "black"}}/>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    )}
                />
            </Form>
        </Container>);
};

export default ModelNewItemFormComponent;
