import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Button from "reactstrap/lib/Button";


const authUrl = process.env.REACT_APP_AUTH_URL;


class HomePage extends Component {
    render() {
        const {t} = this.props;
        return (
            <div>
                <h2 style={{marginBottom: '50px'}}>{t("Welcome to React")}</h2>
                <Button size="lg" color="primary" onClick={() => window.open(authUrl, '_top')}>Login</Button>
            </div>

        )
    }
}

export default withTranslation()(HomePage);
