import React from 'react';
import {GET_LOGIN_DATA, GET_STRUCTURE_SUBCATEGORY} from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";
import {useParams} from "react-router-dom";
import {Query} from "@apollo/client/react/components";
import {Spinner} from "reactstrap";


const StructureSubcategory = () => {

    const {structureId} = useParams();
    return (
        <Query query={GET_LOGIN_DATA}>
            {({data: auth}) => (
                <Query
                    query={GET_STRUCTURE_SUBCATEGORY} variables={{structureId: parseInt(structureId)}} fetchPolicy="network-only">
                    {({loading, error, data}) => {
                        if (loading) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
                        if (error) return <div>{error.message}</div>;
                        const structureSubcategory = data.result.map(({subcategory, model, active, id, numrow}) => {
                            const subcategoryDesc = subcategory.description;
                            const subcategory_id = subcategory.id;
                            const modelDesc = model.description;
                            const model_id = model.id;
                            return ({...{}, "subcategory": subcategoryDesc, "model": modelDesc, "active": active, "id":id, "numrow":numrow, subcategory_id, model_id })
                        });
                        const parentValue = data.result.find(item => item?.structure)?.structure.description
                        return (
                            <GenericListContainer list={structureSubcategory}
                                                  structureName={"StructureSubcategory"}
                                                  parentName={"Structure"}
                                                  parentValue={parentValue}
                                                  btnLabel={"Create StructureSubCategory"}

                            />
                        )
                    }}
                </Query>
            )}
        </Query>
    )
};

export default StructureSubcategory;
