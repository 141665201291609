import React from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner} from 'reactstrap';
import {useMutation} from "@apollo/client";
import {getDeleteMutation, getQuery} from "../Helpers/mutation";
import './ConfimationModalStyles.css';
import {Link} from "react-router-dom";
import {ERRORS} from "../../navigation/Routes";
import styles from "../SubmitModal/submitModal.module.css";
import i18n from "i18next";

import {getDeleteVariables, getVariables} from "../Helpers/functions";
import {useParams} from "react-router-dom";

import './ConfimationModalStyles.css';

const DEFAULT_MESSAGE = "Confermi la cancellazione della riga di rendicontazione?";

const setData = (data, values, structureName) => {
    switch (structureName) {
        case "Financial":
            return {
                ...data,
                result: data.result.filter(val => !data.result.filter(item => item.rowId === values.rowId).includes(val))
            };
        case "Structure":
            return {
                ...data,
                result: [...data.result]
            };
        case "ReasonCode": {
            const deletedIndex = data.result.findIndex(item => item.id === values.id);
            data.result.splice(deletedIndex, 1);
            return {
                ...data,
                result: data.result
            }
        }
        default:
            return {
                ...data,
                result: [...data.result]
            };
    }

};

const getMessage = (structureName, message) => {
  if (message)
      return message;
    else if (structureName === "Project")
        return i18n.t("chooseActionMsg");
    else return DEFAULT_MESSAGE
};

const ConfirmationModal = ({
                               toggleModal,
                               isModalOpen,
                               label = "Canc.",
                               structureName,
                               values,
                               setValues,
                               message,
                               isError,
                               orgVersionId,
                               isLoading = true,
                               doSubmitMutation,
                               setIsLoading,
    isStatusLoading
                           }) => {
    const {structureId} = useParams();
    const [doDelete] = useMutation(getDeleteMutation(structureName));
    return (

        <div>
            <Button color='danger' onClick={() => {
                toggleModal(false);
                setValues(null)
            }}>{label}</Button>
            <Modal isOpen={isModalOpen} toggle={() => {
                toggleModal(false);
                setValues(null)
            }} className="text">
                {<Spinner className={styles.spinner} animation="border" hidden={isLoading}/>}
                {isLoading &&
                    <ModalHeader toggle={() => toggleModal(false)}>{getMessage(structureName, message)}</ModalHeader>}
                    {isError &&
                    <Link to={`${ERRORS}/${orgVersionId}`} onClick={() => toggleModal(false)}>Errori e Avvisi</Link>}

                {(!message && isLoading === undefined) &&
                <ModalBody>
                    <h4>{ DEFAULT_MESSAGE}</h4>
                </ModalBody>}
                <ModalFooter>
                    {(label === "Canc." && structureName !== "Project") && <Button color='danger' onClick={async () => {
                        try {
                            await doDelete({
                                variables: getDeleteVariables(values, structureName),
                                update: (proxy) => {
                                    const data = proxy.readQuery({
                                        query: getQuery(structureName),
                                        variables: getVariables({structureName:structureName, ogrID:orgVersionId, structureId:structureId,values: values})
                                    });
                                    proxy.writeQuery({
                                        query: getQuery(structureName),
                                        variables: getVariables({structureName:structureName, ogrID:orgVersionId, structureId:structureId,values: values}),
                                        data: setData(data, values, structureName)
                                    });
                                }
                            })
                        } finally {
                            toggleModal(false);
                            setValues(null)
                        }
                    }}>OK</Button>}
                    <Button hidden={true} variant="secondary" onClick={() => {
                        toggleModal(false);
                        setValues(null)
                    }}>{label !== "Canc." ? "OK" : "Annulla"}</Button>
                    {structureName === "Project" && <Row>
                        <Col>
                            <Button color="danger" disabled={isStatusLoading} onClick={async () => {
                                try {
                                    setIsLoading(true)
                                    await doSubmitMutation({
                                        variables: {
                                            orgVersionId: values.orgVersionId,
                                            newStatus: "VERIFIED"
                                        }
                                    });
                                } catch (e) {
                                    console.log(e)
                                } finally {
                                    toggleModal(false);
                                    setIsLoading(false)
                                    setValues(null)
                                }
                            }}>{i18n.t("verifyStatusBtn")}</Button>
                        </Col>
                        <Col>
                            <Button color="success"  disabled={isStatusLoading}  onClick={async () => {
                                try {
                                    setIsLoading(true)
                                    await doSubmitMutation({
                                        variables: {
                                            orgVersionId: values.orgVersionId,
                                            newStatus: "APPROVED"
                                        }
                                    });
                                } catch (e) {
                                    console.log(e)
                                } finally {
                                    toggleModal(false);
                                    setIsLoading(false)
                                    setValues(null)
                                }
                            }}>{i18n.t("approveStatus")}</Button>
                            </Col>
                    </Row>}
                </ModalFooter>
            </Modal>
        </div>
    )
};

export default ConfirmationModal;
