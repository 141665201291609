import React, {useState} from 'react';
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { GET_USER_QUERY} from "../../GraphQl/query";
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_USER, UPDATE_USER} from "../../GraphQl/mutation";

const ManageUserModal = ({
                          toggleModal,
                          instituteCode,
                          translate,
                          userRole
                      }) => {

    const [user, setUser] = useState(null);
    const [isUpdate, setIsUpdateMutationType] = useState(false);
    const {loading, data, error} = useQuery(GET_USER_QUERY, {
        variables: { instituteCode },
      });

    React.useEffect(() => {
        if (data) {
            const user = data.result && data.result.length>0 ? data.result[0]:{};
            if(user && user.id){
                setIsUpdateMutationType(!!user);
            }
            setUser(user?user:{})
        }
        else if (loading || error)  setUser({})
    }, [data, error, loading]);


    const getMutation = () =>{
        return isUpdate?UPDATE_USER:CREATE_USER
    };

    const [saveUser] = useMutation(getMutation(), {
        onCompleted(data) {
            // console.log(data);
            // refetch({ instituteCode: instituteCode });
        }
    });
    if(!user) return null;
    return (
        <div>
            <Button color='danger' onClick={() => toggleModal(false)}>{"Add User"}</Button>
            <Modal isOpen={instituteCode ? true:false} toggle={() => toggleModal(false)}>
                <ModalHeader>{translate("manageUser")}</ModalHeader>
                <ModalBody>
                    <Label><strong>{translate("userEmail")}</strong></Label>
                    <Input 
                        type="email" 
                        placeholder={translate("userEmail")} 
                        required 
                        defaultValue={user?user.email:""} 
                        onBlur={(e) => setUser({...user, email: e.target.value})}/>
 
                    <Label className='pt-2'><strong>{translate("userFirstName")}</strong></Label>
                    <Input 
                        type="text" 
                        placeholder={translate("userFirstName")} 
                        required 
                        defaultValue={user?user.name:""} 
                        onBlur={(e) => setUser({...user, name: e.target.value})}/>

                    <Label className='pt-2'><strong>{translate("userLastName")}</strong></Label>
                    <Input 
                        type="text" 
                        placeholder={translate("userLastName")} 
                        required 
                        defaultValue={user?user.surname:""} 
                        onBlur={(e) => setUser({...user, surname: e.target.value})}/>
                </ModalBody>
                <ModalFooter>
                    {/* <Button disabled={!ROLES_TO_SEE_STATUS.includes(userRole)} onClick={() => { */}
                    <Button onClick={() => {
                        saveUser({
                            variables: {
                                userId:user?.id,
                                data: {
                                    email: user.email? user.email : "",
                                    username: user.email? user.email : "",
                                    name: user.name? user.name : "",
                                    surname: user.surname? user.surname : "",
                                    instituteCode: instituteCode
                                    // id: user.id? user.id : "",
                                }
                            },
                            update: (proxy, {data: {result}}) => {
                                const data = proxy.readQuery({
                                    query: GET_USER_QUERY,
                                    variables: { instituteCode },
                                });
                                proxy.writeQuery({
                                    query: GET_USER_QUERY,
                                    variables:{
                                        instituteCode: instituteCode
                                    },
                                    data: {
                                        ...data,
                                        result: isUpdate?data.result:[...data.result,result]
                                    }
                                });
                            }
                        });
                        toggleModal(false)
                    }}>{translate("submitUser")}</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
};
export default ManageUserModal;
