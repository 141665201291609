/**
 * Form row input
 *
 * @see https://github.com/shoaibkhan94/reactstrap-formik
 */
import React from 'react';
import PropTypes from 'prop-types';
import {getFunctionResult} from '../Helpers/calculationFunctions'
import {Col, FormFeedback, FormGroup, Input, Label} from 'reactstrap';

function doCalculation(setFieldValue, calculation, values) {
    values[calculation.accessor] = getFunctionResult(calculation, values);
    return setFieldValue(calculation.accessor, values[calculation.accessor]);
}

const CalculatedInput = ({
                             field: {...fields},
                             form: {touched, errors, setFieldValue, values},
                             maxLength,
                             calculation,
                             labelcols,
                             showuntouched,
                             label,
                             labelClass,
                             formGroupClass,
                             ...props
                         }) =>
    <FormGroup row className={formGroupClass}>
        <Label xs={labelcols} for={fields.name} className={`label-color text-left ${labelClass}`}>{label}</Label>
        <Col xs={12 - labelcols}>
            <Input {...props} {...fields} invalid={(showuntouched || touched[fields.name]) && !!errors[fields.name]}
                   maxLength={maxLength} onBlur = { () => calculation.forEach(calculation => doCalculation(setFieldValue, calculation, values)) }/>
            <FormFeedback>{errors[fields.name]}</FormFeedback>
        </Col>
    </FormGroup>;

CalculatedInput.propTypes = {
    field: PropTypes.object.isRequired, // da Formik: name
    form: PropTypes.object.isRequired,  // da Formik: touched, errors
    label: PropTypes.string.isRequired, // etichetta
    labelcols: PropTypes.number,        // num colonne per etichetta
    showuntouched: PropTypes.bool,       // mostra errore anche campo non touched (es. numerici)
    formGroupClass: PropTypes.string,
    maxLength: PropTypes.number
};

CalculatedInput.defaultProps = {
    labelcols: 3,
    formGroupClass: "",
    showuntouched: false,
    maxLength: null
};

export default CalculatedInput;
