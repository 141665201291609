import React, {Component} from 'react';
import {GET_ERRORS} from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";
import {withRouter} from "react-router-dom";
import {Query} from "@apollo/client/react/components";
import {Spinner} from "reactstrap";


class ErrorListContainer extends Component {

    render() {
        const orgVersionId = this.props.match.params.orgVersionId;
        return (
            <Query
                query={GET_ERRORS} variables={{orgVersionId: orgVersionId}} fetchPolicy="network-only">
                {({loading, error, data}) => {
                    if (loading) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
                    if (error) return <div>{error.message}</div>;
                  
                    let clone = JSON.parse(JSON.stringify(data));
                    
                    //console.log(clone);
                  
                    let i = 0;
                    
                    let new_row = {};
                    let new_row_detail = {};
                    
                    let fresh = {"validationErrors":  new Array()};
                    
                    while (i < clone.validationErrors.length) {
                      
                      
                      //test
                      //clone.validationErrors[i].validationMessage = "errore uno\nerrore due\nerrore tre";
                      
                      
                      if ( (typeof (clone.validationErrors[i].validationMessage) !== "undefined") && ((clone.validationErrors[i].validationMessage) !== null) && ((clone.validationErrors[i].validationMessage).length > 0) ) {
                        
                        
                        
                        const multiple_messages = clone.validationErrors[i].validationMessage.split("\n");
                        
                        new_row = JSON.parse(JSON.stringify(clone.validationErrors[i]));
                        
                        
                 
                        
                        fresh.validationErrors.push(new_row);
                        
                        
                        let z = 0;
                        
                        while (z < multiple_messages.length) {
                          
                          let plus  = multiple_messages[z];
                          
                          new_row_detail = JSON.parse(JSON.stringify(clone.validationErrors[i]));
                          new_row_detail.validationRule.message = "‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ◉‎ ‎ " + plus;
                          
                          fresh.validationErrors.push(new_row_detail);
                          
                          z++;
                        }
                        
                        
                      } else {
                        
                        new_row = JSON.parse(JSON.stringify(clone.validationErrors[i]));
                        
                        fresh.validationErrors.push(new_row);
                        
                      }  
                        
                      i++;
                      
                    }
                  
                    //console.log("++++++++++++++ Fresh +++++++++++++++");
                    //console.log(fresh);
                    //console.log("++++++++++++++++++++++++++++++++++++");                  
                  
                    let errorList = fresh.validationErrors.map(function (items) {
                  
                        //let errorList = data.validationErrors.map(function (items) {
                      
                        //console.log("items: ", items);
                        
                        const validationResult = items.validationResult;
                        const validationMessage = items.validationMessage;
                        
                        //if ( (typeof (items.validationMessage) !== "undefined") && ((items.validationMessage) !== null) && ((items.validationMessage).length > 0) ) {
                        //  
                        //  console.log(items.validationRule.message + "\n" + items.validationMessage);
                        //  
                        //}
                        
                        // items.validationRule.message = validationResult !== "SUCCESS"? items.validationRule.message: "Validazione Positiva";
                        return ({...items.validationRule, validationResult})
                    });
                    
                    
                    return (
                        <GenericListContainer list={errorList}
                                              structureName={"ValidationRule"}

                        />
                    )
                }}
            </Query>
        )
    }
}

export default withRouter(ErrorListContainer);
