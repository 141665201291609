import React from 'react';
import ReactTable from 'react-table';
import {Row, Col, Spinner} from 'reactstrap';
import 'react-table/react-table.css';
import styles from '../Styles/List.module.css'
import {defaultFilterMethod} from "../Helpers/filters";
import {useEffect, useState} from 'react';
import { hash, objectHash, murmurHash, sha256 } from "ohash";

function get_hash(columns) {
  
    let selected_property = "accessor";
    
    let selected_values = [];
    
    for (const obj of columns) {
      selected_values.push(obj[selected_property]);
    }
  
    //return hash(selected_values);
    return hash(columns);
    
}

const GenericListComponent = ({data, columns, setFiltered, inmodal, loading, code, retainFilters}) => {
  
  
   
  
    let tmpfilters = JSON.parse(localStorage.getItem(get_hash(columns))) || [];
  
    if (!retainFilters) {
      
      // non leggo i filtri in caso di Financials
      tmpfilters = [];
      
    }
    
    if (code !== undefined) {
      
      tmpfilters.push({id:'code', value:code});
      
    }
   
    // .push({id:'code', value:code})
  
    const [storedata, setStoreData] = useState([]);
  
    const [filters, setFilters] = useState(
      
      // Carica i filtri iniziali dal local storage
      // quando si cambia form o quando il componente deve
      // essere aggiornato
      
      tmpfilters
    
    );
    
    
    // console.log("FILTERS: ", filters);
    
    
    useEffect(() => {
      
      // acquisire i dati al primo render 
      
      const fetchData = async () => {
        
        // i dati arrivano da prop
        setStoreData(data);
        
      };
      
      fetchData()
      .then((result) => {
        
        //console.log(result);
        
      })
      .catch((error) => {
        
        console.error(error.message);
        
      });
      
    }, []);
  
  
    useEffect(() => {
      
      // Salva i filtri su local storage on filters change
      // Funziona Correttamente
      
      let key = get_hash(columns);
      
      localStorage.setItem(key, JSON.stringify(filters));
      
    }, [filters]);
    
    const handleFilterChange = (updatedFilters) => {
      
      // Setta lo stato dei filtri on change
      
      
      setFilters(updatedFilters);
      
      
      // console.log("updatedFilters: ", updatedFilters);
      
    };
  
    // Luigi: le colonne arrivano qui pre-trattate a vari fini 
    // uno di essi è specificare quale funzione applicare 
    // ed a quali argomenti ai bottoni che invocano i routes
  
    // defaultFiltered={[{id:'code', value:code}]}
    // defaultFilterMethod={defaultFilterMethod}

  
    if (code == undefined)
      code = "";
    
    
    // if (Array.isArray(filters))
        // console.log("filters is an array: ", filters);
    // else
        // console.log("filters not an array: ", typeof filters);
    
    
    const MyLoader = () => loading?
        <div className='-loading -active'>
            <div className='-loading-inner'>
                <Spinner style={{width: '3rem', height: '3rem'}}/>
            </div>
        </div>:null;
    return (
        <div>
            <Row className={inmodal ? styles.tableInModal : styles.tablePadding}>
                <Col md={12} className={styles.table}>
                    <ReactTable
                        onFilteredChange={handleFilterChange}
                        LoadingComponent={MyLoader}
                        data={data}
                        columns={columns}
                        minRows={0}
                        defaultPageSize={20}
                        className="-striped -highlight"
                        style={{overflow:'wrap', height: '65vh'}}
                        previousText="Precedente"
                        nextText="Successiva"
                        noDataText="Nessun dato presente"
                        pageText="Pagina"
                        ofText="di"
                        rowsText="righe"
                        ref={(r) => {
                            setFiltered(r)
                        }}
                        filterable
                        defaultFiltered={filters}
                        defaultFilterMethod={defaultFilterMethod}
                        showPagination={!inmodal}
                    />
                </Col>
            </Row>
        </div>
    )
};

export default GenericListComponent



