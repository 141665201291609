const OUTSIDE_STEPS_DETAILS = `A. Uscire dal sistema di rendicontazione economica.
B. Firmare digitalmente il PDF con il riepilogo della rendicontazione.
C. Fare login nel Workflow.
D. Inviare il documento firmato digitalmente come messaggio proattivo.`;

export const i18nTrans = {
    en: {
        translation: {
            "Welcome to React": "Sistema di Rendicontazione Economica dei Progetti di Ricerca",
            "id": "ID",
            "code": "Codice",
            "year": "Anno",
            "title": "Titolo",
            "startingDate": "Data Inizio",
            "endingDate": "Data Fine",
            "extensionDate": "Data Estensione",
            "coFinancing": "Finanziamento",
            "manager": "Manager",
            "active": "Attivo",
            "createdDate": "Data Inserimento",
            "lastModifiedDate": "Data Modifica",
            "description": "Descrizione",
            "subCategories": "Sottocategorie",
            "Category": "Categorie",
            "models": "Modelli",
            "structureSubcategories": "Structure Subcategories",
            "modelItems": "Campi Modelli",
            "validationResult": "Risultato Validazione",
            "accessedDate": "Data",
            "message": "Messaggio",
            "status": "Stato",
            "verificationPositive": "Verifica eseguita con successo.",
            "verificationNegative": "La verifica non ha avuto esito positivo: clicca qui per vedere il risultato (la sottomissione verrà interrotta).",
            "submitted": "Il progetto è stato confermato con successo",
            "imported": "Importato con successo",
            "outsideSteps": "5. Passi successivi",
            "submitTitle": "Per la sottomissione del progetto eseguire i seguenti passi cliccando su ciascun elemento della lista:",
            "RunVerification": "Verifica il Progetto",
            "DoImport": "Aggiorna il Personale importato dal Workflow della Ricerca",
            "clonedORgVersion": "Clicca per confermare che l'anagrafica del Personale è allineata con il Workflow della Ricerca",
            "SubmitConfirmWithErrors": "Sono stati riscontrati errori o avvisi nella validazione: Confermi la sottomissione del progetto?",
            "SumbmitStep": "Conferma",
            "DownloadPDF": "Scarica il PDF di riepilogo della rendicontazione",
            "outsideStepsDetail": OUTSIDE_STEPS_DETAILS,
            "SUBMITTED": "Confermato",
            "VERIFIED": "Verificato",
            "OPENED": "Aperto",
            "COMPLETED": "Completato",
            "SNAPSHOTTED": "Situazione corrente",
            "CONSOLIDATED": "Consolidato",
            "rejectBtn": 'Rif.',
            "warningBtn": "Sosp.",
            "approveBtn": "App.",
            "selectAll":"Selez. Tutto",
            "deselectAll":"Annulla Sel.",
            "submitComment":"Salva",
            "yourComment": "Verifica",
            "selectStatus":"Stato",
            "selectReasonCode": "Motivazione",
            "leaveComment":"Commento",
            "submitStatus":"Conferma",
            "nestedModal": "Lista Motivazioni",
            "ReasonCode":"",
            "reasonCode":"Motiv.",
            "done": "OK",
            "close": "Annulla",
            "closeAll": "Chiudi tutto",
            "APPROVED": "Approvato",
            "REJECTED": "Non Ammesso",
            "INVESTIGATING": "Da Verificare",
            "noStatus": "No Stato",
            "app": "Appr.",
            "rej":"Non Amm.",
            "inv":"Da ver.",
            "mod":"Modif.",
            "downloadExcel":"Excel",
            "downloadPdf":"Report Analitico",
            "downloadStatus":"Report Verifica",
            "reportSintesiRF":"Report Sintetico",
            "confirmStatus":"Conferma",
            "verifyStatus":"Invia Verifica",
            "verifyStatusBtn":"Osservazioni",
            "approveStatus":"Approvata",
            "clone": "Duplica",
            "reopen": "Riapri",
            "isCloned": "Dupl.",
            "reply": "Note Istituto",
            "MODIFIED":"Modificato",
            'conf':'Confermato',
            'ver':'Verificato',
            'open':'Aperto',
            'attachment': "Allegato",
            "chooseAction":"Invia Verifica",
            "chooseActionMsg": "Invia Verifica",
            "calcWarning": "La formula deve avere almeno 2 parametri, Multiply by Ratio deve avere 3 parametri",
            "validationRuleList": "Regole di validazione",
            "toCategories": "Categorie",
            "toSubcategories": "Sottocategorie",
            "toStructure":"Struttura Progetti",
            "toModels": "Modelli",
            "config":"Attività",
            "saveModel": "Modello",
            "addItem":"Aggiungi Campo",
            "remove":"Elimina",
            "add":"Aggiungi",
            "preview":"Anteprima",
            "submit":"Salva",
            "Structure": "Struttura Progetti",
            "StructureSubcategory":"Sottocategorie",
            "numrow":"Num. Riga",
            "statusBtn":"Status Button",
            "approveAll": "Set status Approve to all non status rows",
            "changeSelectedStatus":"Change Status for selected rows",
            "uploadEnabled": "Importazione abilitata",
            "inactive":"Non attivo",
            "subcategory": "Subcategory",
            "model":"Model",
            "validationRules": "Regole di Validazione",
            "messagePrint": "Messaggio Stampa",
            "expression": "Espressione Validazione",
            "instituteDescription": "Istituto",
            "move_up": "Sposta Su",
            "move_down": "Sposta Giù",
            "items": "Campi",
            "ValidationRule": "Verifiche",
            "snapshot": "Fotografa situazione corrente",
            "cons": "Consolida",
            "CONSOLIDATED_EXISTS": "Progetto già consolidato",
            "NOT_COMPLETED": "Unità Operative non completate",
            "GENERIC_ERROR":"Impossibile effettuare l'operazione",
            "managerPage": "Capo Progetto",
            "projectVersion": "Rendicontaz.",
            "orgVersion": "Unità Operative",
            "startPeriodDate": "Data Inizio",
            "endPeriodDate": "Data Fine",
            "organization": "Descrizione",
            "manageUser": "Gestione Utente", 
            "userEmail": "Email SPID",
            "userFirstName": "Nome",
            "userLastName": "Cognome",
            "submitUser": "Salva Utente",
            "ProjectUnits": "Unità Operative",
            "previousSubmissionsNumber": "N. Sottomissione Prec.",
            "cumulativeEligibleCost": "Costo Eleggibile Cumulato",
            "submitProjectVersion": "Salva",
            "manageProjectVersion": "Aggiungi",
            "FINAL": "Rendicontazione Finale",
            "INTERIM": "Rendicontazione Intermedia",
            "versionType": "Tipo rendicontazione",
            "project": "Progetto",
            "selectVerionType": "Seleziona tipo di rendicontazione",
            "manageProjectUnit": "Unità operativa",
            "fiscalCode": "Codice Fiscale Organizzazione",
            "projectUnitAssociatedTo": "Organizzazione: ",
            // "unitDescription": "Descrizione",      
            "unitData": "Dati unità operativa",
            "cup": "CUP",
            "submitProjectUnit": "Salva unità",
            "createOrganization": "Organizzazione non trovata! Crea organizzazione",
            "organizationDescription": "Nome",
            "organizationLongDescription": "Descrizione",
            "legalRepresentativeFiscalCode": "Codice Fiscale Rap. Legale",
            "legalRepresentativeName": "Nome Rap. Legale",
            "legalRepresentativeSurname": "Cognome Rap. Legale",
            "submitOrganization": "Salva Organizzazione"


        }
    }
};

export const lang = "en";

const keys = Object.keys(i18nTrans.en.translation);

export const findKey = (value) => {
    return keys.find(key => i18nTrans.en.translation[key] === value)
};
