import React, {useState, useEffect} from 'react';
import {
    Button,
    ListGroup,
    ListGroupItem,
    ListGroupItemHeading, ListGroupItemText,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Spinner,
} from 'reactstrap';
import {UPDATESTATUS, VERIFY} from "../GraphQl/mutation";
import {useMutation} from "@apollo/client";
import {Link} from "react-router-dom";
import {ERRORS} from "../../navigation/Routes";
import {DOWNLOAD_URL} from "../GraphQl";
import {useTranslation} from "react-i18next";

import styles from './submitModal.module.css';
import {useQuery} from "@apollo/client";
import {GET_LOGIN_DATA, GET_PRJ_INFO} from "../GraphQl/query";
import {getImportMutation} from "../Helpers/functions";

import { COMPLETED, CONSOLIDATED, OPENED, SUBMITTED } from '../Helpers/constants';

const getStatus = (hasUnits, status, t) => {
    if(!hasUnits){
        return SUBMITTED
    }
    else {
        return status === t(OPENED)? COMPLETED:SUBMITTED
    }
}

const SubmitModal = ({
                         toggleModal,
                         isModalOpen,
                         values,
                     }) => {
    const token = localStorage.getItem('token');
    const {hasUnits, financialsApi} = values ? values.structureInfo : {financialsApi: null, hasUnits:null, utcDeadline: null}
    const modelId = values.structureInfo.model?.id
    const {t} = useTranslation();
    const status = values?.status
    const cleanState = () => {
        setIsLoading(false);
        setIsImported(false);
        setImportResult("");
        setIsSubmitted(false);
        setVerificationResult(false);
        setSubmitResult("");
        setIsVerificationDone(false);
        setMessage("")
    };
    const isCloned = values ? !!values.parent : false;
    const importMutation = getImportMutation(financialsApi)
    const [verificationResult, setVerificationResult] = useState(false);
    const [submitResult, setSubmitResult] = useState("");
    const [isVerificationDone, setIsVerificationDone] = useState(false);
    const [isImported, setIsImported] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [message, setMessage] = useState("");
    const [importResult, setImportResult] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isConsolidated, setIsConsolidated] = useState(false);
    const {data: year} = useQuery(GET_PRJ_INFO);
    const {data: userInfo} = useQuery(GET_LOGIN_DATA)
    const projectYear = year ? year.year - 1 : 0;
    const [doVerification] = useMutation(VERIFY, {
        onCompleted(data) {
            setIsLoading(false);
            setVerificationResult(data.verifyOrganizationVersion);
            data.verifyOrganizationVersion ? setMessage("verificationPositive") : setMessage("verificationNegative");
            setIsVerificationDone(true);
        }
    });
    const [doSubmit] = useMutation(UPDATESTATUS, {
        onCompleted(data) {
            setIsLoading(false);
            setIsSubmitted(true);
            setSubmitResult("submitted")
        }
    });
    const [doImport] = useMutation(importMutation, {
        onCompleted(data) {
            if (data)
                setIsLoading(false);
            setImportResult("imported");
            setIsImported(true);
        }
    });
    const runVerification = async () => {
        setIsLoading(true);

        try {
            await doVerification({variables: {orgVersionId: values.orgVersionId}})
        } catch (e) {
            setIsLoading(false);
            setMessage(e.message)
        }
    };
    const makeSubmit = async () => {
        setIsLoading(true);
        try {
            await doSubmit({variables: {orgVersionId: values.orgVersionId, newStatus: getStatus(hasUnits, status, t)}})
        } catch (e) {
            setIsLoading(false);
            setSubmitResult(e.message)
        }
    };
    const makeImport = async () => {
        if (isCloned || !modelId) {
            setIsImported(true);
            return;
        }
        setIsLoading(true);
        try {
            await doImport({
                variables: {
                    projectYear: projectYear,
                    orgVersionId: values.orgVersionId,
                    instituteCode:userInfo?.userInfo.role === "ADMIN"? parseInt(values.orgVersionInstCode):parseInt(userInfo?.userInfo.instituteCode),
                    projectCode: values.externalProjectId,
                    modelId: modelId
                }
            })
        } catch (e) {
            setIsLoading(false);
            setImportResult(e.message)
        }
    };
    const printPdf = () => window.open(`${DOWNLOAD_URL}${values.orgVersionId}/pdf?token=${token}`, '_blank');

    useEffect(() => {
        if(!modelId || status === t(CONSOLIDATED)) setIsImported(true);
        // if (status === t(CONSOLIDATED) || !STRUCTURES_TO_CONSOLIDATE.includes(values?.structureId)) setIsConsolidated(true);
        if (status === t(CONSOLIDATED) || !hasUnits) setIsConsolidated(true);
    }, [hasUnits, modelId, status, t])

    // PM 20210521 chiusura bloccata con data parametrica della struttura
    const deadline = values?.structureInfo.utcDeadline;
    if (deadline && Date.now() > new Date(deadline)) {
        console.log('CHIUDI', values?.structureInfo.id);
        return (
            <Modal isOpen={isModalOpen} toggle={() => toggleModal(false)}>
                <ModalHeader>
                    {`La sottomissione dei progetti ${values.structureInfo.description} si è conclusa il ${new Date(deadline).toLocaleDateString('it-IT')}`}
                </ModalHeader>
                <ModalFooter>
                <Button color="danger" onClick={() => toggleModal(false)}>OK</Button>
            </ModalFooter>
            </Modal>
        )
    }
        
    return (
        <Modal size="lg" isOpen={isModalOpen} toggle={() => {
            toggleModal(false);
            cleanState()
        }}>
            <ModalHeader>{t("submitTitle")}</ModalHeader>
            <ModalBody>
                <ListGroup flush>
                    {(status === t(OPENED)) && modelId && <ListGroupItem tag={isImported ? undefined : 'button'} action onClick={makeImport}>
                        <ListGroupItemHeading>{isCloned ? t("clonedORgVersion") : t('DoImport')}</ListGroupItemHeading>
                        {importResult &&
                        <ListGroupItemText>{t(importResult)}</ListGroupItemText>}
                    </ListGroupItem>}
                    {(isImported) &&
                    <ListGroupItem tag={isVerificationDone ? undefined : 'button'} action onClick={runVerification}>
                        <ListGroupItemHeading>{t('RunVerification')}</ListGroupItemHeading>
                        {isVerificationDone &&
                        <ListGroupItemText tag={isSubmitted || verificationResult ? undefined : Link}
                                           to={`${ERRORS}/${values.orgVersionId}`}>{t(message)}</ListGroupItemText>}
                    </ListGroupItem>}
                    {(verificationResult && isVerificationDone) &&
                    <ListGroupItem tag={isSubmitted ? undefined : 'button'} action onClick={makeSubmit}>
                        <ListGroupItemHeading>{t('SumbmitStep')}</ListGroupItemHeading>
                        {isSubmitted &&
                        <ListGroupItemText className={styles.confirmation}>
                            {t(submitResult)}
                        </ListGroupItemText>}
                    </ListGroupItem>}
                    {(!verificationResult && isVerificationDone) &&
                    <ListGroupItem>
                        <ListGroupItemHeading>{t('SumbmitStep')}</ListGroupItemHeading>
                        <ListGroupItemText>
                            {t('SubmitConfirmWithErrors')}
                            {' '}
                            <Button outline color="danger" size="sm" disabled={isSubmitted}
                                    onClick={() => makeSubmit()}>Confermo
                            </Button>
                        </ListGroupItemText>
                        {isSubmitted &&
                        <ListGroupItemText className={styles.confirmation}>
                            {t(submitResult)}
                        </ListGroupItemText>}
                    </ListGroupItem>}
                    {(submitResult && !isCloned) &&
                    <ListGroupItem tag="button" action onClick={printPdf}>
                        <ListGroupItemHeading className={styles.pdf}>{t('DownloadPDF')}</ListGroupItemHeading>
                    </ListGroupItem>}
                    {(submitResult && !isCloned && (!hasUnits || isConsolidated)) &&
                    <ListGroupItem>
                        <ListGroupItemHeading>{t('outsideSteps')}</ListGroupItemHeading>
                        <ListGroupItemText>{t('outsideStepsDetail')}</ListGroupItemText>
                    </ListGroupItem>}
                </ListGroup>
            </ModalBody>
            <Spinner className={styles.spinner} animation="border" hidden={!isLoading}/>
            <ModalFooter>
                <Button color={isSubmitted ? 'success' : 'danger'}
                        onClick={() => {
                            toggleModal(false);
                            cleanState()
                        }}>{isSubmitted ? 'OK' : 'Annulla'}</Button>
            </ModalFooter>
        </Modal>
    );
};

export default SubmitModal;
