import React from 'react';
import PropTypes from 'prop-types';
import {FilePond, registerPlugin} from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import axios from 'axios';


import {Col, FormGroup, Label} from 'reactstrap';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { baseURLmicroServiceFile } from "../Helpers/constants";

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginImagePreview);

const APPLICATIONFOLDER = "finreport/";



const FileUpload = ({
                        field: {...fields},
                        labelcols,
                        label,
                        api,
                        onload,
                        prefix,
                        ...props
                    }) => {

    const uploadFile = async (file, metadata, load, error, progress, abort) => {
        try {
            const { data: { url: { url, fields }, filename }} = await axios.post(baseURLmicroServiceFile + '/write', { fileName: APPLICATIONFOLDER + prefix + file.name });
            const fd = new FormData();
            Object.keys(fields).forEach(key => {
                fd.append(key, fields[key]);
            });
            fd.append('file', file);
            await axios.post(url, fd);
            onload(filename);
            load(filename) // Let FilePond know the processing is done
        } catch (error) {
            console.error(error);
        }
        return {
            abort: () => {
            // This function is entered if the user has tapped the cancel button
            // Let FilePond know the request has been cancelled
            abort()
            },
        }
    };
    
    const server = {
        revert: null,
        restore: null,
        load: null,
        fetch: null,
        // url: baseURLmicroServiceFile,
        // process: {
        //     url: 'upload/' + APPLICATIONFOLDER,
        //     method: 'POST',
        //     onload: onload,
        // }
        process: (fieldName, file, metadata, load, error, progress, abort) => uploadFile(file, metadata, load, error, progress, abort)
    };
    return (
        <FormGroup row>
            <Label md={labelcols} for={fields.name} className="label-color">{label}</Label>
            <Col md={12 - labelcols}>
                <FilePond
                    // acceptedFileTypes={
                    //     ['application/octet-stream']
                    // }
                    // fileValidateTypeLabelExpectedTypes={'Tipo file accettato: excel'}
                    maxFileSize={'5MB'}
                    allowRevert={true}
                    instantUpload={true}
                    labelIdle={'Trascina il file o <span class="filepond--label-action"> Esplora</span> - Dimansione massima 5 MB'}
                    labelFileProcessing="Caricamento"
                    labelFileProcessingError="Errore nel caricamento"
                    labelFileProcessingComplete="Caricamento completato"
                    labelTapToCancel=""
                    server={server}
                    {...props}
                />
            </Col>
        </FormGroup>
    );
}

FileUpload.propTypes = {
    field: PropTypes.object.isRequired, // da Formik: name
    label: PropTypes.string.isRequired, // etichetta
    labelcols: PropTypes.number,        // num colonne per etichetta
    prefix: PropTypes.string,          // prefisso per il nome del file
    // onload: PropTypes.func.isRequired,  // setValue
    // api: PropTypes.shape({
    //   url: PropTypes.string,
    //   process: PropTypes.object
    // }).isRequired    // oggetto configurazione api
};

FileUpload.defaultProps = {
    labelcols: 3,
    prefix: '',
};

export default FileUpload;
